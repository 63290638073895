import {HedgeMatrixRow, HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {
    CellClassParams,
    ColDef,
    ColGroupDef, ColumnMovedEvent, DisplayedColumnsChangedEvent,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams, GridColumnsChangedEvent,
    GridOptions,
    GridReadyEvent, ICellEditorParams,
    IsColumnFuncParams,
    ITooltipParams,
    MenuItemDef,
    ValueSetterParams,
} from "ag-grid-community";
import {AgGridColumn} from "ag-grid-angular";
import * as Enumerable from "linq";
import {ValueFormatterParams, ValueGetterParams} from "ag-grid-community/dist/lib/entities/colDef";
import {centeredColumnDef} from "../../ag-grid-contrib";
import {defaultCurrencyFormatter, getShortUUID, isValidNumber, isVoid, makePortfolioKey,} from "../../utils";
import {HedgeData} from "./hedge-data";
import {HedgeMatrixCustomColumnHeaderGroup} from "./hedge-matrix-custom-colgroup-header";
import {HgMatrixPunchPadComponent} from "./punch-pad/hg-matrix-punch-pad.component";
import {HedgeExpirationCellEditorComponent} from "./hedge-expiration-cell-editor.component";

const nanoWidth = 60;
const microWidth = 70;
const miniWidth = 80;
const minWidth = 90;
const colWidth = 100;
const maxWidth = 110;

export function getHedgeMatrixGridOptions(comp: HgHedgeMatrixComponent): GridOptions {
    const options: GridOptions = {};
    options.defaultColDef = centeredColumnDef;
    const otmColumn = getOtmColumn(comp);
    const strikesColumn = getStrikeColumn(comp);
    const totalColumns = getTotalColumns(comp);
    options.columnDefs = [
        otmColumn,
        strikesColumn,
        ...totalColumns
    ];
    options.rowClass = 'ets-text-centered';
    options.rowSelection = null;
    options.suppressRowClickSelection = true;
    options.rowModelType = 'clientSide';
    options.rowData = [];
    options.tooltipShowDelay = 250;
    options.suppressDragLeaveHidesColumns = true;
    options.applyColumnDefOrder = true;
    options.frameworkComponents = {
        matrixTotalGroupHeader: HedgeMatrixCustomColumnHeaderGroup,
        punchPadCellEditor: HgMatrixPunchPadComponent,
        hedgeExpirationCellEditor:HedgeExpirationCellEditorComponent
    }

    options.onColumnMoved = (args : ColumnMovedEvent) => {
        comp.updateVisibleColumns();
    }
    options.onDisplayedColumnsChanged = (args: DisplayedColumnsChangedEvent) => {
        comp.updateVisibleColumns();

    }

    options.getRowNodeId = (data) => data.strike || getShortUUID()
    options.onFirstDataRendered = (args: FirstDataRenderedEvent) => args.columnApi.autoSizeAllColumns();
    options.onGridReady = (args: GridReadyEvent) => comp.onGridReady(args);
    options.getContextMenuItems = (args: GetContextMenuItemsParams) => {

        const menuItems : (string | MenuItemDef)[] = [];

        const colDef = args.column.getColDef() || {};

        const hedgeData = colDef['ets-data'] as HedgeData;

        const isHedgeColumn = !isVoid(hedgeData);

        const isNewHedge = isHedgeColumn && hedgeData.isNew;;

        if (isHedgeColumn && !isNewHedge) {

            const hedgeData = colDef['ets-data'] as HedgeData;

            const colGroupId = `${hedgeData.id}:colgroup`;

            const colGroup = args.columnApi.getColumnGroup(colGroupId);

            if (!isVoid(colGroup)) {

                const isModificationMode = colGroup.getDisplayedChildren().length  > 1;

                let text = 'Modify Hedge';

                if (isModificationMode) {
                    text = 'Exit Modification Mode';
                }

                const modifyItem : MenuItemDef = {
                    name: text,
                    action: () => {
                        comp.modifyHege(args.column);
                    }
                };

                menuItems.push(modifyItem);

                menuItems.push('separator');
            }
        }

        const addHedgeItem : MenuItemDef = {
            name: 'Add Hedge',
            subMenu: [
                {
                    name: 'Call',
                    action: () => comp.addHedge('Call', args.column)
                },
                {
                    name: 'Put',
                    action: () => comp.addHedge('Put', args.column)
                }
            ]
        };
        menuItems.push(addHedgeItem);

        const removeHedgeItem: MenuItemDef = {
            name: 'Remove Hedge',
            action : () => comp.removeHedge(hedgeData?.id),
        };

        if (hedgeData && hedgeData.isNew) {
            menuItems.push(removeHedgeItem);
        }

        menuItems.push({
            name: 'Copy Orders To...',
            subMenu: [
                {
                    name: 'ML Pad',
                    action: () => comp.copyOrders(hedgeData, 'ml-pad')
                },
                {
                    name: 'Hedging Grid',
                    action: () => comp.copyOrders(hedgeData, 'hg')
                },
                {
                    name: 'ToS',
                    action: () => comp.copyOrders(hedgeData, 'tos')
                }
            ]
        });

        menuItems.push('autoSizeAll');
        menuItems.push('sizeColumnsToFit');

        return menuItems;
    }


    return options;
}

export function getColumnDefs(comp: HgHedgeMatrixComponent, isEmpty: boolean): Partial<AgGridColumn>[] {

    const cols: Partial<AgGridColumn>[] = [];

   const pinnedColumns = getPinnedColumns(comp);

   cols.push(pinnedColumns);

    if (!isEmpty) {

        const hedges = Enumerable.from(
            comp.hedgeMatrixDataService.getHedges()
        );

        const expirations = comp.expirationList.map(x => {
            const column = getExpirationColumn(comp, x);
            return column;
        });

        cols.push(...expirations);

        const hedgesColumns = hedges
            .select(h => getHedgeColumn(comp, h))
            .toArray();

        cols.push(...hedgesColumns);
    }

    const totals = getTotalColumns(comp);

    cols.push(...totals);

    const pnlExpirations = comp.pnlExpirationsList.flatMap(x => {
        const column = getPnlExpirationGroup(comp, x, false);
        const allInclusive = getPnlExpirationGroup(comp, x, true);
        return [column, allInclusive];
    });
    cols.push(...pnlExpirations);

    const uniqueExpirations = Enumerable.from(comp.expirationList)
        .select(x => x.expiration)
        .distinct()
        .toArray();

    const grandTotalCols = uniqueExpirations.map(x => {
        const exp = comp.expirationList.find(y => y.expiration === x);
        const col = getGrandTotalPnlExpirationColumn(comp, exp);
        return col;
    });

    cols.push(...grandTotalCols);

    const spacer = getSpacerColumn();

    cols.push(spacer);

    return cols;
}

function getPinnedColumns(comp: HgHedgeMatrixComponent): ColGroupDef {
    const otmCol = getOtmColumn(comp);
    const strikeCol = getStrikeColumn(comp);

    const group: ColGroupDef = {
        groupId: 'pinned',
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                side: 'grand-totals-all'
            },
        },
        children: [otmCol, strikeCol],
    }

    return group;
}

function getStrikeColumn(comp: HgHedgeMatrixComponent): ColDef {
    const col: ColDef = {
        headerName: 'Strike',
        pinned: true,
        field: 'strike',
        colId: 'strike',
        minWidth: miniWidth,
        width: miniWidth,
        headerClass: 'ets-text-centered',
        sortable: false,
        cellStyle(args: CellClassParams) {

            if (!args.data) {
                return undefined;
            }

            if (args.data.isPinned) {
                return {
                    background: '#2a2a2a',
                    color: 'gold',
                    'font-weight': 'bold',
                };
            }

            const styleObj = {
                color: 'white',
                'font-weight': '800',
                background: 'inherit'
            };

            const bgColors = [];

            const atmStrike = comp.getAtmStrike();

            if (args.data.strike === atmStrike) {
                bgColors.push('white');
            }

            let portfolioLegColors = comp.hedgeMatrixDataService
                .getPortfolioLegColorByStrike(args.data.strike);

            if (comp.showPortfolioPositions) {
                if (portfolioLegColors.length > 0) {
                    bgColors.push(...portfolioLegColors);
                }
            }

            if (args.data && (args.data.strike === comp.centerStrike)) {
                bgColors.push('black');
            }

            if (bgColors.length === 0) {
                return styleObj;
            }

            let background;

            if (bgColors.length > 0) {
                if (bgColors.length === 1) {
                    background = bgColors[0];
                } else if (bgColors.length === 2) {
                    background = `linear-gradient(to right, ${bgColors[0]} 0% 50%, ${bgColors[1]} 50% 100%);`;
                } else if (bgColors.length === 3) {
                    background = `linear-gradient(to right, ${bgColors[0]} 0% 33%, ${bgColors[1]} 33% 66%, ${bgColors[2]} 66% 100%);`;
                } else if (bgColors.length === 4) {
                    background = `linear-gradient(to right, ${bgColors[0]} 0% 25%, ${bgColors[1]} 25% 50%, ${bgColors[2]} 50% 75%, ${bgColors[3]} 75% 100%);`;
                }
            }

            styleObj['background'] = background;

            if (bgColors.indexOf('white') >= 0) {
                styleObj['color'] = 'black';
            }

            if (bgColors.indexOf('black') >= 0) {
                if (bgColors.indexOf('orange') === -1) {
                    styleObj['color'] = 'gold';
                }
            }

            return styleObj;
        },
    }

    return col;
}

export function getHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {
    if (hedge.isNew) {
        return getNewHedgeColumn(comp, hedge);
    } else {
        return getExistingHedgeColumn(comp, hedge);
    }
}

function getExistingHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {

    const colDef: ColGroupDef = {
        headerName: hedge.type.toUpperCase() + 'S',
        marryChildren: true,
        groupId: `${hedge.id}:colgroup`,
        children: [
            {
                headerName: hedge.name,
                colId: hedge.id,
                minWidth: colWidth,
                width: colWidth,
                sortable: false,
                cellStyle: getHedgeColumnCellStyle(comp),
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="color: ${hedge.color};">
                          <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                          <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                          <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: center">
                            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                            ${hedge.name} 
                            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                        </div>`
                },
                tooltipValueGetter: (params: ITooltipParams) => {
                    if (!params.data.isPinned) {
                        return undefined;
                    }
                    return hedge.name;
                },
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (params.data.isPinned) {
                         if (params.data.isExpiration || params.data.isDayOfWeek) {
                            const val = params.data.isExpiration
                                ? hedge.expiration
                                : hedge.dayOfWeek
                            return val;
                        } else if (params.data.isHedgePrice) {
                            return comp.getHedgePriceFormatted(hedge)
                        } else {
                            return undefined;
                        }
                    }

                    if (isVoid(strike)) {
                        return undefined;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        strike,
                        hedge.id
                    );

                    if (isVoid(cellData)) {
                        return null;
                    }

                    return cellData.qty;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (!isValidNumber(value)) {
                        return null;
                    }

                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data && params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                return defaultCurrencyFormatter(value);
                            }
                        }
                        return value;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        strike,
                        hedge.id
                    );

                    const lastQuote = comp.lastQuoteCache.getLastQuote(cellData.ticker);

                    if (isVoid(lastQuote)) {
                        return null;
                    }

                    let sign = '';
                    if (value > 0) {
                        sign = '+';
                    }

                    const quote = defaultCurrencyFormatter(lastQuote.mid);

                    const v = `${quote} (${sign}${value}) `;

                    return v;
                },
            },
            {
                headerName: 'Trans',
                colId: `${hedge.id}:trans`,
                field: 'transQty',
                headerClass: 'opg-difference',
                minWidth: colWidth,
                width: colWidth,
                hide: true,
                sortable: false,
                cellEditorSelector: (params: ICellEditorParams) => {
                    const isMouseClick = isVoid(params.charPress) && isVoid(params.keyPress);
                    if (isMouseClick) {
                        return {component: 'punchPadCellEditor'};
                    }
                },
                cellEditorParams: {
                  etsComponent: comp
                },
                cellStyle: getModifierColumnCellStyle(),
                editable: (params: IsColumnFuncParams) => !params.data.isPinned,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService.getTransCost(hedge.id);
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? hedge.expiration
                                    : hedge.dayOfWeek
                                return val;
                            }
                        }
                        return null;
                    }

                    const transQty = comp.hedgeMatrixDataService.getTransQty(strike, hedge.id);
                    return transQty;
                },
                valueSetter: (params: ValueSetterParams) => {
                    const strike = params.data.strike;
                    if (!isValidNumber(strike, true)) {
                        return null;
                    }
                    let transQty = parseInt(params.newValue);
                    if (!isValidNumber(transQty, true)) {
                        transQty = null;
                    }
                    comp.hedgeMatrixDataService.setTransQty(hedge, strike, transQty);
                    setTimeout(() => comp.recalculatePnls());
                    return true;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (!isValidNumber(value)) {
                        return null;
                    }

                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data && params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                return defaultCurrencyFormatter(value);
                            }
                        }
                        return value;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        strike, hedge.id
                    );

                    const ticker = cellData?.ticker;

                    if (isVoid(ticker)) {
                        return value;
                    }

                    comp.lastQuoteCache.subscribeIfNotYet(ticker);

                    const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

                    if (isVoid(lastQuote)) {
                        return value;
                    }

                    let sign = '';

                    if (value > 0) {
                        sign = '+';
                    }

                    const quote = defaultCurrencyFormatter(lastQuote.mid);

                    const v = `${quote} (${sign}${value}) `;

                    return v;
                }
            },
            {
                headerName: 'Outcome',
                colId: `${hedge.id}:outcome`,
                field: 'outcomeQty',
                headerClass: 'opg-difference',
                minWidth: colWidth,
                width: colWidth,
                hide: true,
                sortable: false,
                cellEditorSelector: (params: ICellEditorParams) => {
                    const isMouseClick = isVoid(params.charPress) && isVoid(params.keyPress);
                    if (isMouseClick) {
                        return {component: 'punchPadCellEditor'};
                    }
                },
                cellEditorParams: {
                    etsComponent: comp
                },
                cellStyle: getModifierColumnCellStyle(),
                editable: (params: IsColumnFuncParams) => !params.data.isPinned,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;
                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService.getOutcomeCost(hedge.id);
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? hedge.expiration
                                    : hedge.dayOfWeek
                                return val;
                            }
                        }
                        return null;
                    }
                    const outcomeQty = comp.hedgeMatrixDataService
                        .getOutcomeQty(strike, hedge.id);
                    return outcomeQty;
                },
                valueSetter: (params: ValueSetterParams) => {
                    const strike = params.data.strike;
                    if (!isValidNumber(strike, true)) {
                        return null;
                    }
                    let outcomeQty = parseInt(params.newValue);
                    if (!isValidNumber(outcomeQty, true)) {
                        outcomeQty = null;
                    }
                    comp.hedgeMatrixDataService.setOutcomeQty(hedge, strike, outcomeQty);
                    setTimeout(() => comp.recalculatePnls());
                    return true;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (!isValidNumber(value)) {
                        return null;
                    }

                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data && params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                return defaultCurrencyFormatter(value);
                            }
                        }
                        return value;
                    }

                    const cellData = comp.hedgeMatrixDataService
                        .getCellData(strike, hedge.id);

                    const ticker = cellData?.ticker;

                    if (isVoid(ticker)) {
                        return value;
                    }

                    comp.lastQuoteCache.subscribeIfNotYet(ticker);

                    const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

                    if (isVoid(lastQuote)) {
                        return value;
                    }

                    let sign = '';

                    if (value > 0) {
                        sign = '+';
                    }

                    const quote = defaultCurrencyFormatter(lastQuote.mid)

                    const v = `${quote} (${sign}${value}) `;

                    return v;
                }
            }
        ]
    };

    colDef.children.forEach(c => c['ets-data'] = hedge);

    return colDef;
}

function getNewHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {

    const colDef: ColGroupDef = {
        headerName: hedge.type.toUpperCase() + 'S',
        marryChildren: true,
        groupId: `${hedge.id}:colgroup`,
        children: [
            {
                headerName: hedge.name,
                colId: hedge.id,
                minWidth: colWidth,
                width: colWidth,
                sortable: false,
                cellStyle: getModifierColumnCellStyle(),
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="color: ${hedge.color};">
                          <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                          <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                          <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: center">
                            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                            ${hedge.name} 
                            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                        </div>`
                },
                cellEditorSelector: (args: ICellEditorParams) => {
                    if (!args.data.isExpiration) {
                        return null;
                    }
                    return {
                        component: 'hedgeExpirationCellEditor'
                    }
                },
                cellEditorParams: {
                    api: comp.theGrid,
                    etsComponent: comp,
                    hedgeId: hedge.id
                },
                editable: (params: IsColumnFuncParams) => !params.data.isPinned || params.data.isExpiration || params.data.isDayOfWeek,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService.getTransCost(hedge.id);
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? hedge.expiration
                                    : hedge.dayOfWeek
                                return val;
                            }
                        }
                        return null;
                    }

                    const transQty = comp.hedgeMatrixDataService.getTransQty(strike, hedge.id);
                    return transQty;
                },
                valueSetter: (params: ValueSetterParams) => {
                    const strike = params.data.strike;
                    if (!isValidNumber(strike, true)) {
                        return null;
                    }
                    let transQty = parseInt(params.newValue);
                    if (!isValidNumber(transQty, true)) {
                        transQty = null;
                    }
                    comp.hedgeMatrixDataService.setTransQty(hedge, strike, transQty);
                    setTimeout(() => comp.recalculatePnls());
                    return true;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (!isValidNumber(value)) {
                        return null;
                    }

                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data && params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                return defaultCurrencyFormatter(value);
                            }
                        }
                        return value;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        strike, hedge.id
                    );

                    const ticker = cellData?.ticker;

                    if (isVoid(ticker)) {
                        return value;
                    }

                    comp.lastQuoteCache.subscribeIfNotYet(ticker);

                    const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

                    if (isVoid(lastQuote)) {
                        return value;
                    }

                    let sign = '';

                    if (value > 0) {
                        sign = '+';
                    }

                    const quote = defaultCurrencyFormatter(lastQuote.mid);

                    const v = `${quote} (${sign}${value}) `;

                    return v;
                }
            },
        ]
    };

    colDef.children.forEach(c => c['ets-data'] = hedge);

    return colDef;
}

function getExpirationColumn(comp: HgHedgeMatrixComponent, expiration: {
    expiration: string,
    side: 'Call' | 'Put',
    expirationFull: string,
    dayOfWeek: string
}) {
    const colId = `${expiration.expiration}:expiration:${expiration.side}`;
    const col: ColDef = {
        headerName: `${expiration.side.toUpperCase()}S`,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        // hide: !comp.isColumnVisible(colId),
        hide: true,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expiration
                        : expiration.dayOfWeek
                    return val;
                } else {
                    return undefined;
                }
            }

            const ticker = comp.hedgeMatrixDataService.getTickerForCell(
                params.data.strike, expiration.expiration, expiration.side
            );
            const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);
            return lastQuote?.mid;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }
    return col;
}

function getTotalColumns(comp: HgHedgeMatrixComponent): ColDef[] {
    const totalCalls: ColDef = getTotalColumn(comp, 'Call');
    const totalPuts: ColDef = getTotalColumn(comp, 'Put');
    return [totalCalls, totalPuts];
}

function getTotalColumn(comp: HgHedgeMatrixComponent, side: 'Call' | 'Put'): ColDef {

    const totalColumn: ColDef = {
        headerName: `TOTAL ${side.toUpperCase()}S`,
        minWidth: colWidth,
        maxWidth: colWidth,
        sortable: false,
        colId: `${side}:total`,
        cellStyle: getTotalColumnCellStyle(comp, side),
        valueGetter: (params: ValueGetterParams) => {

            const rowData = params.data as HedgeMatrixRow;

            if (isVoid(rowData)) {
                return null;
            }

            const expirations = Enumerable.from(comp.getSelectedHedges(side))
                .select(x => x.getColDef())
                .where(x => !isVoid(x))
                .select(x => x['ets-data'] as HedgeData)
                .where(x => !isVoid(x))
                .select(x => x.expiration)
                .distinct()
                .toArray();

            const hasMixed = expirations.length > 1;

            if (hasMixed && !rowData.isHedgePrice) {
                return null;
            }

            const strike = params.data.strike;

            const visible = comp.getSelectedHedges(side).map(x => x.getColId());

            if (rowData.isHedgePrice) {
                if (visible.length === 0) {
                    return null;
                }

                const hp = comp.hedgesPricingService
                    .getHedgesCostPicked(visible);

                const transCosts = visible.map(vh =>  comp.hedgeMatrixDataService.getTransCost(vh) || 0);

                const transSum = transCosts.reduce((p,c) => p +c ,0) * -1;

                const total = transSum + hp;

                return total;
            }

            const totalQty = comp.hedgeMatrixDataService.getTotalQtyForStrike(
                strike,
                side,
                visible
            );

            if (isVoid(totalQty)) {
                return null;
            }

            return totalQty;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            const value = params.value;

            if (!isValidNumber(value)) {
                return null;
            }

            const rowData = params.data as HedgeMatrixRow;

            if (rowData && rowData.isHedgePrice) {
                return defaultCurrencyFormatter(value);
            }

            const ticker = side === 'Call'
                ? params.data.callTicker
                : params.data.putTicker;

            const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

            if (isVoid(lastQuote)) {
                return value;
            }

            let sign = '';
            if (value > 0) {
                sign = '+';
            }

            const quote = defaultCurrencyFormatter(lastQuote.mid)
            const v = `${quote} (${sign}${value}) `;

            return v;
        },
    };

    const grp : ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                side: side
            },
        },
        groupId: `${side}:total:group`,
        children: [totalColumn]
    }

    return grp;
}

function getOtmColumn(comp: HgHedgeMatrixComponent): ColDef {
    const col: ColDef = {
        headerName: '$ OTM',
        pinned: true,
        field: 'otm',
        colId: 'otm',
        minWidth: microWidth,
        maxWidth: microWidth,
        sortable: false,
        headerClass: 'ets-text-centered',
        cellStyle: (params: CellClassParams) => {
            if (params.data.isPinned) {
                return {
                    background: '#2a2a2a',
                    'font-weight': 'bold'
                };
            }
        }
    }
    return col;
}


//
// Pnl expirations
//
function getPnlExpirationGroup(comp: HgHedgeMatrixComponent, expiration: {
    expiration: string,
    expirationFull: string,
    side: 'Call' | 'Put'
    dayOfWeek: string
}, allInclusive: boolean) {
    const col = getPnlExpirationColumn(comp, expiration, allInclusive);

    const groupId =  allInclusive
        ?`${expiration.expiration}:pnl-expiration:${expiration.side}:all:group`
        :`${expiration.expiration}:pnl-expiration:${expiration.side}:group`;

    const grp : ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                expiration: expiration.expiration,
                side: expiration.side,
                allInclusive: true
            },
        },
        groupId: groupId,
        children: [col]
    }

    return grp;
}

function getPnlExpirationColumn(comp: HgHedgeMatrixComponent, expiration: {
    expiration: string,
    expirationFull: string,
    side: 'Call' | 'Put'
    dayOfWeek: string
}, allInclusive: boolean): ColDef {

    const header =  allInclusive
        ? `P&L ${expiration.side.toUpperCase()}S (All)`
        : `P&L ${expiration.side.toUpperCase()}S`;

    const colId =  allInclusive
        ?`${expiration.expiration}:pnl-expiration:${expiration.side}:all`
        :`${expiration.expiration}:pnl-expiration:${expiration.side}`;

    const col: ColDef = {
        headerName: header,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        // hide: !comp.isColumnVisible(colId),
        hide: true,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expiration
                        : expiration.dayOfWeek
                    return val;
                } else if (params.data.isHedgePrice) {

                    let visible = allInclusive
                        ? comp.hedgeMatrixDataService.getHedges().map(x => x.id)
                        : comp.getVisibleHedges();

                    const hedgeIds = comp
                        .hedgeMatrixDataService
                        .getHedges()
                        .filter(x => visible.indexOf(x.id) >= 0)
                        .filter(x => x.expiration === expiration.expiration)
                        .filter(x => x.type === expiration.side);

                    const pnls = hedgeIds.map(x => comp.getHedgePrice(x));
                    const sum = pnls.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                    return sum;
                } else {
                    return undefined;
                }
            }

            let visible = allInclusive
                ? comp.hedgeMatrixDataService.getHedges().map(x => x.id)
                : comp.getVisibleHedges();

            return comp.hedgeMatrixDataService.getExpirationPnl(
                expiration.expiration,
                params.data.strike,
                expiration.side,
                visible
            );
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }

    return col;
}

function getGrandTotalPnlExpirationColumn(comp: HgHedgeMatrixComponent, expiration: {
    expiration: string,
    expirationFull: string,
    side: 'Call' | 'Put'
    dayOfWeek: string
}) {
    const colId = `${expiration.expiration}:grandtotalpnl`;
    const col: ColDef = {
        headerName: `P&L TOTAL`,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        // hide: !comp.isColumnVisible(colId),
        hide: true,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expiration
                        : expiration.dayOfWeek
                    return val;
                } else if (params.data.isHedgePrice) {

                    let visible = comp.getVisibleHedges();

                    const hedgeIds = comp
                        .hedgeMatrixDataService
                        .getHedges()
                        .filter(x => visible.indexOf(x.id) >= 0)
                        .filter(x => x.expiration === expiration.expiration);

                    const pnls = hedgeIds.map(x => comp.getHedgePrice(x));
                    const sum = pnls.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                    return sum;

                } else {
                    return undefined;
                }
            }

            const visible = comp.getVisibleHedges();

            const callPnl = comp.hedgeMatrixDataService.getExpirationPnl(
                expiration.expiration,
                params.data.strike,
                'Call',
                visible
            );

            const putPnl = comp.hedgeMatrixDataService.getExpirationPnl(
                expiration.expiration,
                params.data.strike,
                'Put',
                visible
            );

            return callPnl + putPnl;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }

    const grp : ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                expiration: expiration.expiration,
                side: 'grand-totals',
            },
        },
        groupId: `${expiration.expiration}:grandtotalpnl:group`,
        children: [col]
    }

    return grp;
}

function getSpacerColumn(): ColDef {
    const col : ColDef = {
        headerName: '< SPACER >',
        colId: 'spacer',
        width: colWidth,
        enableRowGroup: false,
        showRowGroup: true,
        lockVisible: true,
        cellStyle: (args: CellClassParams) => {

            const styleObj = {};

            styleObj['font-weight'] = 'normal';
            styleObj['background-color'] = 'rgba(0,0,0,0)';
            styleObj['color'] = undefined;
            styleObj['border-right-color'] = 'rgb(66, 66, 66)';

            if (args.data && args.data.isPinned) {
                styleObj['background-color'] = '#2a2a2a';
            }

            return styleObj;
        }
    }
    return col;
}

function getHedgeColumnCellStyle(comp: HgHedgeMatrixComponent) {

    const f = function getCellStyle(args: CellClassParams) {

        const styleObj = {};
        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';


        const hedgeData: HedgeData = args.colDef['ets-data'];

        if (isVoid(hedgeData)) {
            return styleObj;
        }

        if (args.data.isPinned) {
            if (args.data.isExpiration || args.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            } else if (args.data.isHedgePrice) {

                const value = comp.getHedgePrice(hedgeData);

                styleObj['background-color'] = '#2a2a2a';

                if (value < 0) {
                    styleObj['color'] = 'red';
                } else if (value > 0) {
                    styleObj['color'] = 'green';
                }
            }
        } else {

            const strike = args.data.strike;

            const leg = hedgeData.legs.find(x => x.strike === strike);

            if (isVoid(leg)) {
                return styleObj;
            }

            styleObj['background-color'] = getColor(leg.qty);

            styleObj['color'] = 'white';

        }

        return styleObj;
    };

    return f;

}

function getTotalColumnCellStyle(comp: HgHedgeMatrixComponent, side: 'Call' | 'Put') {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        let styleObj = {};

        const rowData = args.data as HedgeMatrixRow;

        if (rowData && rowData.isPinned) {
            styleObj = {
                background: '#2a2a2a',
                color: 'white',
                'border-right-color': 'rgb(66, 66, 66)'
            };

            if (rowData.isHedgePrice) {
                const val = args.value + '';
                const replaced = val.replace('$', '');
                const number = parseFloat(replaced);

                if (number < 0) {
                    styleObj['color'] = 'red';
                } else if (number > 0) {
                    styleObj['color'] = 'green';
                }
            }

            return styleObj;
        }

        styleObj['font-weight'] = 'normal';
        styleObj['background'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';

        if (isVoid(args.value)) {
            return styleObj;
        }

        const strike = args.data.strike;

        const visible = comp
            .getSelectedHedges(side)
            .map(x => x.getColId());

        const qty = comp.hedgeMatrixDataService
            .getTotalQtyForStrike(strike, side, visible);

        if (!isValidNumber(qty, true)) {
            return styleObj;
        }

        styleObj['background-color'] = getColor(qty);

        styleObj['color'] = 'white';

        return styleObj;

    };

    return f;

}

function getColor(qty: number) {
    return qty > 0 ? '#00007c' : 'darkred';
}

function getExpirationColumnCellStyle(comp: HgHedgeMatrixComponent) {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        const styleObj = {};

        // styleObj['border-color'] = 'black';
        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';

        if (args.data.isPinned) {
            if (args.data.isExpiration || args.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            } else if (args.data.isHedgePrice) {
                styleObj['background-color'] = '#2a2a2a';

                const val = args.value + '';
                const replaced = val.replace('$', '');
                const number = parseFloat(replaced);

                if (number < 0) {
                    styleObj['color'] = 'red';
                } else if (number > 0) {
                    styleObj['color'] = 'green';
                }
            }
        }

        return styleObj;
    };

    return f;

}

function getModifierColumnCellStyle() {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        const styleObj = {};

        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';


        if (args.data.isPinned) {

            styleObj['background-color'] = '#2a2a2a';

            if (args.data.isHedgePrice) {

                const value = parseFloat(args.value);

                if (!isValidNumber(value)) {
                    return styleObj;
                }

                if (value < 0) {
                    styleObj['color'] = 'red';
                } else if (value > 0) {
                    styleObj['color'] = 'green';
                }

            } else if (args.data.isExpiration || args.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            }

            return styleObj;
        }

        const val = parseFloat(args.value);

        if (!isValidNumber(val, true)) {
            return styleObj;
        }

        styleObj['background-color'] = getColor(val);

        styleObj['color'] = 'white';

        return styleObj;

    };
    return f;
}