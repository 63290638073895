import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    Input
} from '@angular/core';
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {
    arraysEqual,
    delay,
    DetectMethodChanges, DetectSetterChanges,
    DxValueChanged,
    findAtmStrikeIndex, findHCF,
    getShortUUID, isOptionExpired, isValidNumber,
    isVoid, makeGuiFriendlyExpirationDate,
} from "../../utils";
import {HedgePositionModel} from "../positions-section/hedge-positions/hedge-position.model";
import {TradingInstrument} from "../../trading-instruments/trading-instrument.class";
import {TradingInstrumentsService} from "../../trading-instruments/trading-instruments-service.interface";
import * as Enumerable from "linq";
import {OptionsChainService} from "../../option-chains.service";
import {makeOptionTicker, OptionStrategy, OptionType, parseOptionTicker} from "../../options-common/options.model";
import {isNullOrUndefined} from "util";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {SettingsStorageService} from "../../settings-storage-service.service";
import {ToastrService} from "ngx-toastr";
import {getIdentity, HedgePosition} from "../data-model/hedge-position";
import {MessageBusService} from "../../message-bus.service";
import {BeforePositionDto} from "../../adjustment-pricing-grid/model/BeforePositionDto";
import {
    HedgePackageToMerge,
    HgMergeDialogComponent,
    HgMergeDialogConfig
} from "./merge-dialog/hg-merge-dialog.component";
import {OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {takeUntil} from 'rxjs/operators';
import {from, Observable, Subject} from "rxjs";
import {DxNumberBoxComponent} from "devextreme-angular/ui/number-box";
import {ClipboardService} from "../../clipboard.service";
import {
    ComboHighlightedItem,
    ComboHighlightedUIMessage,
    MultiLegOrderDataUIMessage
} from "../../ui-messages/ui-messages";
import {PortfolioItemType} from "../../portfolios/portfolios.model";
import {SessionService} from "../../authentication/session-service.service";
import {
    GenericConfirmationDialogComponent
} from "../../generic-confirmation-dialog/generic-confirmation-dialog.component";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {
    HedgesSortingDialogComponent,
    HedgesSortingDialogConfig
} from "./sorting-dialog/hedges-sorting-dialog.component";
import {OrdersChainDialogComponent} from "../../orders-chain-dialog/orders-chain-dialog.component";
import {OrdersChainDialogConfig} from "../../orders-chain-dialog/orders-chain-dialog.config";
import {CopyOrdersToService} from "../../adjustment-pricing-grid/services/copy-orders-to.service";
import {ShellClientService} from "../../shell-communication/shell-client.service";
import {SettlementPriceService} from "../settlement-price.service";

type HgStateKey = 'hg.strikeWidth' | 'hg.strikeOffset' | 'hg.selectedStrategy'
    | 'hg.selectedOptionType' | 'hg.baseQty' | 'hg.autoSort';

function getHgStateKeys(): HgStateKey[] {
    return [
        'hg.strikeWidth',
        'hg.strikeOffset',
        'hg.selectedStrategy',
        'hg.selectedOptionType',
        'hg.baseQty',
        'hg.baseQty',
        'hg.autoSort'
    ];
}

export type LegsGroup = {
    side: 'Call' | 'Put';
    groupId: string;
    groupLabel: string,
    groupColor: string,
    groupOrder: number;
    legs: HedgePositionModel[],
    expirationsLinked: boolean;
    hcf?: number;
    hfcToDefaultQtyFactor?: number;
    selectedForMerge?: boolean;
    selectedForHarvest?: boolean;
};

@Component({
    selector: 'ets-hg-settings-section',
    templateUrl: 'hg-settings-section.component.html',
    styleUrls: [
        './hg-settings-section.component.scss',
        '../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HgSettingsSectionComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _optionsChainService: OptionsChainService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _settingsStorageService: SettingsStorageService,
        private readonly _apgDataService: ApgDataService,
        private readonly _toastr: ToastrService,
        private readonly _messageBus: MessageBusService,
        private readonly _clipboardService: ClipboardService,
        private readonly _sessionService: SessionService,
        private readonly _copyOrdersToService: CopyOrdersToService,
        private readonly _settlementPriceService: SettlementPriceService
    ) {
    }

    private _unsubscriber = new Subject();
    private _selectedPortfolio: ApgPortfolio;
    private _callLegsByGroup: LegsGroup[] = undefined;
    private _putLegsByGroup: LegsGroup[] = undefined;
    private _baseState: { before: string[], after: string[] };
    private _initialPositions: HedgePosition[] = [];
    private _initialColorsAndLabels: Enumerable.IDictionary<string, { color: string; label: string }>;
    private _groupsStateIndex: { [ix: string]: boolean } = {};

    private get initialPositions(): HedgePosition[] {
        return this._initialPositions;
    }

    private set initialPositions(value: HedgePosition[]) {
        this._initialPositions = value;

        this._initialColorsAndLabels = Enumerable.from(value)
            .select(x => `${x.groupId}|${x.label}|${x.color}`)
            .distinct()
            .select(x => {
                const parts = x.split('|');
                return {
                    groupId: parts[0],
                    label: parts[1],
                    color: parts[2]
                }
            })
            .toDictionary(
                x => x.groupId,
                x => ({label: x.label, color: x.color})
            );
    }

    @Output() reviewChangesClicked = new EventEmitter<boolean>();

    expandConfigurator: boolean = false;

    strategyList: Array<OptionStrategy> = [
        'BOS',
        'Butterfly',
        'Condor',
        'Option - Long',
        'Option - Short',
        'Slingshot',
        'Slingshot - Modified',
        'Sponsored Long',
        'Vertical',
    ];

    private _isShortVersion = true;
    get isShortVersion(): boolean {
        return this._isShortVersion;
    }

    @DetectSetterChanges()
    set isShortVersion(value: boolean) {

        if (value && this.isMergeMode) {
            return;
        }

        this._isShortVersion = value;

        if (value) {
            setTimeout(() => {
                if (this.isMergeMode) {
                    this.cancelMergeOperation();
                }
            });
        }
    }

    selectedStrategy: OptionStrategy;

    selectedOptionType: 'Calls' | 'Puts' | 'Calls & Puts' = 'Calls';

    strikeWidth: number = 10;

    strikeOffsetMode: 'Offset' | 'Strike' = 'Offset';

    strikeOffset: number = 50;

    baseStrike: number;

    baseQty = 10;

    autoSort = true;

    priceToOpen = false;

    positions: HedgePositionModel[] = [];

    tradingInstrument: TradingInstrument;

    selectedExpiration: OptionExpirationDescriptor;

    expirationList: OptionExpirationDescriptor[] = [];

    dxCenterTextStyleObj = {
        style: 'text-align: center'
    };

    @ViewChild(HgMergeDialogComponent)
    mergeDialogCmp: HgMergeDialogComponent;

    @ViewChild(GenericConfirmationDialogComponent)
    confirmationDialog: GenericConfirmationDialogComponent;

    @ViewChild(HedgesSortingDialogComponent)
    hedgesSortingDialog: HedgesSortingDialogComponent;

    @ViewChild(OrdersChainDialogComponent)
    ordersChainDialog: OrdersChainDialogComponent;

    isMergeMode = false;

    @Input() portfolioDefaultQty?: number;

    getSign = Math.sign;

    get hasCalls(): boolean {
        return this.positions.some(x => x.type === 'Call');
    }

    get hasPuts(): boolean {
        return this.positions.some(x => x.type === 'Put');
    }

    get optionTypeList(): any[] {
        if (isVoid(this.selectedStrategy)) {
            return [];
        }

        const v: any[] = ['Calls', 'Puts'];

        switch (this.selectedStrategy) {

            case "Butterfly - Double":
            case 'Collar':
            case 'Ladder':
            case 'Risk Reversal':
            case 'Iron Butterfly':
            case 'Iron Condor':
            case 'Strangle':
            case 'Straddle':
                v.push('Calls & Puts');
                break;
        }

        return v;
    }

    get isMergeDialogVisible(): boolean {
        return this.mergeDialogCmp?.visible;
    }

    get usedColors(): string[] {
        const used = this.getAllGroups().map(x => x.groupColor)
            .filter((val, ix, arr) => arr.indexOf(val) == ix);
        return used;
    }

    get canShowCustomSortDialog() {
        const noChangesCall = this.getLegGroups('Call').every(x => !this.doesGroupHaveAnyChanges(x));
        const noChangesPut = this.getLegGroups('Put').every(x => !this.doesGroupHaveAnyChanges(x));
        return noChangesCall && noChangesPut;
    }

    ngOnInit() {
        this._messageBus.of<{ side: 'Calls' | 'Puts' }>('Hg.AfterStateApplied')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onAfterStateApplied(x.payload));

        this._messageBus.of('Hg.RefreshQuotes')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(() => this.onChange());

        this._messageBus.of<{ positions: HedgePosition[] }>('Hg.InitialPositionsChanged')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe((msg) => {
                this.onInitialPositionsChanged(msg.payload);
            });

        this._messageBus.of('Hg.AfterReviewChanges')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(() => this.afterReviewChanges());

        this._messageBus.of('Hg.HedgesCleared')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(msg => this.onHedgesCleared(msg.payload));

        this._messageBus.of('ClipboardUpdated')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(() => this._changeDetector.detectChanges());
    }

    @DetectMethodChanges()
    private onInitialPositionsChanged(payload: { positions: HedgePosition[] }) {
        this.initialPositions = payload.positions;
        this.isMergeMode = false;
    }

    ngAfterViewInit() {
        this.hedgesSortingDialog.hedgesReordered$.subscribe(x => this.onCustomSortChanged());
    }

    ngOnDestroy() {
        this._unsubscriber.next();
        this._unsubscriber.complete();
    }

    getLegGroups(side: 'Call' | 'Put'): LegsGroup[] {

        if (side !== 'Call' && side !== 'Put') {
            return;
        }

        let groupField = side === 'Call' ? this._callLegsByGroup : this._putLegsByGroup;

        if (!isVoid(groupField)) {
            return groupField;
        }

        let grpsEnum = Enumerable
            .from(this.positions)
            .where(x => x.type === side)
            .groupBy(x => x.groupId + x.label)
            .select(x => {

                let legs = x.toArray();

                if (this.autoSort) {
                    legs = Enumerable.from(legs)
                        .orderByDescending(x => x.strike)
                        .toArray();
                }

                const groupId = legs[0].groupId;

                const label = legs[0].label;

                const groupOrder = legs[0].groupOrder;

                let linkedState = this._groupsStateIndex[groupId];

                if (isNullOrUndefined(linkedState)) {
                    linkedState = true;
                }

                return {
                    side: side,
                    groupId: groupId,
                    groupLabel: label,
                    groupColor: legs[0].color,
                    groupOrder: groupOrder,
                    legs: legs,
                    expirationsLinked: linkedState
                } as LegsGroup;
            });

        if (this.autoSort) {
            grpsEnum = grpsEnum.orderByDescending(grp => grp.legs[grp.legs.length - 1].strike)
                .thenBy(grp => grp.groupLabel)
        } else {
            grpsEnum = grpsEnum.orderBy(grp => grp.groupOrder);
        }

        const grps = grpsEnum.toArray();

        grps.forEach(grp => grp.legs.forEach(leg => {
            const lastQuote = this._lastQuoteCache.getLastQuote(leg.getTicker());
            leg.price = lastQuote?.mid;
        }));

        if (side === 'Call') {
            this._callLegsByGroup = groupField = grps;
        } else {
            this._putLegsByGroup = groupField = grps;
        }

        return groupField;

    }

    @DetectMethodChanges()
    onChange() {
    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio) {

        this._settlementPriceService.reset();

        this._selectedPortfolio = args;

        let positionModels: HedgePositionModel[] = [];

        try {
            positionModels = await this._hedgePositionsService.getPositionModels(args);
            positionModels.forEach(x => x.expirationPnl = undefined);
        } catch (e) {
            this._toastr.error('Hedge Positions Restored With Errors.\nProbably one or more contracts are expired');
        }

        this.initialPositions = positionModels.map(x => x.asDto());

        this.positions = positionModels.flatMap(x => x);

        let portfolioPositions: BeforePositionDto[][] = [];
        try {
            portfolioPositions = await this._apgDataService.getPortfolioPositions(args);
        } catch (e) {
            console.error(e);
            this._toastr.error('Portfolio Positions Loaded With Errors.\nProbably one or more contracts are expired');
        }

        if (!isVoid(portfolioPositions)) {

            await this.isPortfolioValid();

        } else {
            this._toastr.error('Cannot Determine Portfolio Positions')
        }

        if (!isVoid(this.positions)) {

            const underlying = this.positions[0].underlying;

            if (!isVoid(underlying)) {
                const ti = this._tiService.getInstrumentByTicker(underlying);
                await this.onInstrumentSelected(ti);
            }

        } else {
            if (!isVoid(portfolioPositions)) {
                const optionTicker = parseOptionTicker(portfolioPositions[0][0].ticker);
                if (!isVoid(optionTicker)) {
                    if (!isVoid(optionTicker.underlying)) {
                        const ti = this._tiService.getInstrumentByTicker(optionTicker.underlying);
                        await this.onInstrumentSelected(ti);
                    }
                }
            }
        }

        this.refreshLegs('Calls & Puts');

        this.updateQuoteSubscriptions(this.positions, true);

        this.restoreHgState();
    }

    @DetectMethodChanges()
    removeLeg(leg: HedgePositionModel, side: 'Call' | 'Put') {
        if (this.isOwnedLeg(leg)) {
            leg.removed = true;
        } else {
            this.positions = this.positions.filter(x => x !== leg);
            this.refreshLegs(side);
        }
    }

    @DetectMethodChanges()
    restoreLeg(leg: HedgePositionModel) {
        leg.removed = false;
    }

    @DetectMethodChanges()
    onLabelChanged(group: LegsGroup, args: DxValueChanged<string>) {

        if (isVoid(args.value)) {
            this._toastr.error("Hedge's label cannot be empty string");
            return;
        }

        group.legs.forEach(l => l.label = args.value?.trim());
    }

    @DetectMethodChanges({isAsync: true})
    async onAddStrategyClicked() {

        if (isVoid(this.selectedOptionType)) {
            this._toastr.error('Please provide "Option Type"');
            return;
        }

        if (isVoid(this.selectedStrategy)) {
            this._toastr.error('Please provide "Option Strategy"');
            return;
        }

        if (!isValidNumber(this.strikeWidth)) {
            if (!this.selectedStrategy.startsWith('Option')) {
                this._toastr.error('Please provide "Strike Width"');
                return;
            }
        }

        if (!isValidNumber(this.baseQty, true)) {
            this._toastr.error('Please provide valid "Base Qty"');
            return;
        }

        const models = await this.setupPricingModel();

        this.positions.push(...models);

        this.refreshLegs(this.selectedOptionType);

        this.updateQuoteSubscriptions(models, true);
    }

    private refreshLegs(side: 'Calls' | 'Call' | 'Puts' | 'Put' | 'Calls & Puts' | 'Both') {

        if (isVoid(side) || side === 'Calls & Puts' || side === 'Both') {
            this._callLegsByGroup = undefined;
            this._putLegsByGroup = undefined;
        } else {
            if (side.startsWith('C')) {
                this._callLegsByGroup = undefined;
            } else if (side.startsWith('P')) {
                this._putLegsByGroup = undefined;
            }
        }
    }

    async setupPricingModel(): Promise<HedgePositionModel[]> {

        const strategy = this.selectedStrategy;

        if (isNullOrUndefined(strategy)) {
            return Promise.resolve(null);
        }

        const underlying = this.tradingInstrument.ticker;

        const chain = await this._optionsChainService.getChain(underlying);

        const nearestExp = this.selectedExpiration;

        const lastQuote = await this._lastQuoteCache
            .getLastQuoteWithAwait(underlying);

        const centerIx = findAtmStrikeIndex(nearestExp.strikes, lastQuote);

        const legs: HedgePositionModel[] = [];

        const expirationList = chain.expirations;

        const baseQty = this.baseQty || 1;

        const offset = this.strikeOffsetMode === 'Offset' ? (this.strikeOffset || 0) : 0;

        const atmStrike = this.strikeOffsetMode === 'Offset'
            ? nearestExp.strikes[centerIx] : this.baseStrike;

        function pickStrike(distance: number) {

            if (distance === 0) {
                return atmStrike;
            }

            let targetStrike = atmStrike + distance;

            return targetStrike;
        }


        if (strategy === 'BOS') {
            const width = this.strikeWidth;

            const offset = this.strikeOffset || 0;

            const oType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';

            const multiplier = this.selectedOptionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const label = oType + ' BOS';

            const groupId = getShortUUID();

            const step = this.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * this.baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = firstStrike;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;
            leg1.strategy = strategy;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying;
            leg2.side = 'Sell';
            leg2.qty = -1 * this.baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;
            leg2.strategy = strategy;


            const creditSpreadQty = width / step;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying;
            leg3.side = 'Sell';
            leg3.qty = -creditSpreadQty * this.baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;
            leg3.strategy = strategy;

            const leg4 = new HedgePositionModel();
            leg4.underlying = this.tradingInstrument.underlying;
            leg4.side = 'Buy';
            leg4.qty = creditSpreadQty * this.baseQty;
            leg4.expirationDescriptor = nearestExp;
            leg4.expirationList = expirationList;
            leg4.strike = leg3.strike + step * multiplier;
            leg4.type = oType;
            leg4.label = label;
            leg4.groupId = groupId;
            leg4.strategy = strategy;

            legs.push(leg1, leg2, leg3, leg4);

            if (this.selectedOptionType === 'Calls') {
                legs.reverse();
            }

        }
        else if (strategy === 'Butterfly') {

            const optionType = this.selectedOptionType !== 'Puts'
                ? 'Call'
                : 'Put';

            const multiplier = optionType === 'Put' ? -1 : 1;

            const groupId = getShortUUID();

            const label = optionType + ' Butterfly';

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = optionType;
            leg1.label = label
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying
            leg2.qty = -2 * baseQty;
            leg2.side = 'Sell';
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.type = optionType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying
            leg3.side = 'Buy';
            leg3.qty = 1 * baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.type = optionType;
            leg3.label = label;
            leg3.groupId = groupId;

            legs.push(leg1, leg2, leg3);

            if (optionType === 'Call') {
                legs.reverse();
            }
        } else if (strategy === 'Condor') {

            const offset = this.strikeOffset || 0;
            const width = this.strikeWidth;

            const oType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';

            const multiplier = this.selectedOptionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;


            const label = oType + ' Condor';
            const groupId = getShortUUID();


            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * this.baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = firstStrike;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;


            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying;
            leg2.side = 'Sell';
            leg2.qty = -1 * this.baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying;
            leg3.side = 'Sell';
            leg3.qty = -1 * this.baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + width * 2 * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;

            const leg4 = new HedgePositionModel();
            leg4.underlying = this.tradingInstrument.underlying;
            leg4.side = 'Buy';
            leg4.qty = 1 * this.baseQty;
            leg4.expirationDescriptor = nearestExp;
            leg4.expirationList = expirationList;
            leg4.strike = leg3.strike + width * multiplier;
            leg4.type = oType;
            leg4.label = label;
            leg4.groupId = groupId;

            legs.push(leg1, leg2, leg3, leg4);

            if (this.selectedOptionType === 'Calls') {
                legs.reverse();
            }

        } else if (strategy === 'Option - Long') {

            const strike = this.selectedOptionType === 'Calls'
                ? pickStrike(offset || 0)
                : pickStrike(-offset || 0)

            const oType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * this.baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = strike;
            leg1.type = oType;
            leg1.label = 'Long ' + oType;
            leg1.groupId = getShortUUID();

            legs.push(leg1);

        } else if (strategy === 'Option - Short') {
            const optionType = this.selectedOptionType !== 'Puts'
                ? 'Call'
                : 'Put';
            const multiplier = optionType === 'Put' ? -1 : 1;
            const oType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';
            const strike = atmStrike + offset * multiplier

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying;
            leg1.side = 'Sell';
            leg1.qty = -1 * baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = strike;
            leg1.type = oType;
            leg1.label = 'Short ' + oType;
            leg1.groupId = getShortUUID();

            legs.push(leg1);

        } else if (strategy === 'Vertical') {

            const optionType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';
            const multiplier = this.selectedOptionType === 'Puts' ? -1 : 1;

            const label = this.selectedStrategy;

            const groupId = getShortUUID();

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = optionType;
            leg1.label = label;
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying;
            leg2.side = 'Sell';
            leg2.qty = -1 * baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.type = optionType;
            leg2.label = label;
            leg2.groupId = groupId;

            legs.push(leg2, leg1);

            if (optionType === 'Put') {
                legs.reverse();
            }
        } else if (strategy === 'Slingshot') {

            const oType = this.selectedOptionType !== 'Puts'
                ? 'Call'
                : 'Put';

            const multiplier = oType === 'Put' ? -1 : 1;

            const label = oType === 'Call' ? 'Call Slingshot' : 'Put Slingshot';
            const groupId = getShortUUID();

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying
            leg2.qty = -2 * baseQty;
            leg2.side = 'Sell';
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying
            leg3.qty = 2 * baseQty;
            leg3.side = 'Buy';
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;

            legs.push(leg1, leg2, leg3);

            if (oType === 'Call') {
                legs.reverse();
            }
        } else if (strategy === 'Slingshot - Modified') {

            const oType = this.selectedOptionType !== 'Puts'
                ? 'Call'
                : 'Put';

            const multiplier = oType === 'Put' ? -1 : 1;

            const label = oType === 'Call' ? 'C. Slingshot - Modified' : 'P. Slingshot - Modified';
            const groupId = getShortUUID();

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying
            leg2.qty = -2 * baseQty;
            leg2.side = 'Sell';
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying
            leg3.qty = 2 * baseQty;
            leg3.side = 'Buy';
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;

            const leg4 = new HedgePositionModel();
            leg4.underlying = this.tradingInstrument.underlying
            leg4.qty = -1 * baseQty;
            leg4.side = 'Sell';
            leg4.expirationDescriptor = nearestExp;
            leg4.expirationList = expirationList;
            leg4.strike = leg3.strike + this.strikeWidth * multiplier;
            leg4.type = oType;
            leg4.label = label;
            leg4.groupId = groupId;

            legs.push(leg1, leg2, leg3, leg4);

            if (oType === 'Call') {
                legs.reverse();
            }
        } else if (strategy === 'Sponsored Long') {
            const width = this.strikeWidth;

            const offset = this.strikeOffset || 0;

            const oType = this.selectedOptionType !== 'Puts' ? 'Call' : 'Put';

            const multiplier = this.selectedOptionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const label = oType + ' Sponsored Long';

            const groupId = getShortUUID();

            const step = this.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 = new HedgePositionModel();
            leg1.underlying = this.tradingInstrument.underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * this.baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = firstStrike;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;
            leg1.strategy = strategy;

            const creditSpreadQty = width / step;

            const leg2 = new HedgePositionModel();
            leg2.underlying = this.tradingInstrument.underlying;
            leg2.side = 'Sell';
            leg2.qty = -creditSpreadQty * this.baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;
            leg2.strategy = strategy;

            const leg3 = new HedgePositionModel();
            leg3.underlying = this.tradingInstrument.underlying;
            leg3.side = 'Buy';
            leg3.qty = creditSpreadQty * this.baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;
            leg3.strategy = strategy;

            legs.push(leg1, leg2, leg3);

            if (this.selectedOptionType === 'Calls') {
                legs.reverse();
            }

        } else {
            console.error('Unknown strategy');
            return undefined;
        }

        if (!this.autoSort) {
            legs.forEach(x => x.groupOrder =  this.selectedOptionType === 'Calls' ? 1000 : -1);
        }

        return legs;
    }

    @DetectMethodChanges({isAsync: true})
    async onAddOptionClicked(type: 'Call' | 'Put') {
        const chain = await this._optionsChainService.getChain(this.tradingInstrument.underlying);

        const model = new HedgePositionModel();
        model.qty = this.baseQty;
        model.underlying = this.tradingInstrument.underlying;
        model.groupId = getShortUUID();
        model.expirationList = chain.expirations;
        model.expirationDescriptor = this.selectedExpiration;
        model.type = type;

        this.positions.push(model);

        this.refreshLegs(type);
    }

    @DetectMethodChanges({isAsync: true})
    async onClearLegsClicked(silent?: boolean) {

        if (this.positions.length > 0) {
            if (!silent) {
                try {
                    await this.confirmationDialog?.show(['Are you sure you want to clear current legs?']);
                } catch {
                    return;
                }
            }
        }

        const positions = this.positions.slice()
        this.positions = [];
        this.isMergeMode = false;
        this.refreshLegs('Calls & Puts');
        this.updateQuoteSubscriptions(positions, false, true);
    }

    @DetectMethodChanges({isAsync: true})
    async onReloadHedgesClicked() {

        if (this.positions.length > 0) {
            try {
                await this.confirmationDialog?.show(['Are you sure you want to clear current legs and reload initial hedges?']);
            } catch {
                return;
            }
        }

        this._messageBus.publish({
            topic: 'Hg.HedgesCleared',
            payload: {isReload: true}
        });

        const positions = this.positions.slice();

        this.updateQuoteSubscriptions(positions, false, true);

        this.positions = [];

        this._groupsStateIndex = {};

        this._initialColorsAndLabels = undefined;

        this.isMergeMode = false;

        this.refreshLegs('Calls & Puts');

        if (isVoid(this._selectedPortfolio)) {
            return;
        }

        const positionModels =
            await this._hedgePositionsService.getPositionModels(this._selectedPortfolio);

        this.positions = positionModels.flatMap(x => x);

        this.updateQuoteSubscriptions(this.positions, true);

        this.afterReviewChanges();
    }

    @DetectMethodChanges()
    onLegQtyChange(leg: HedgePositionModel, qty: number) {
        if (isVoid(leg.side)) {
            leg.qty = qty;
            return;
        }
        const q = Math.abs(qty);
        const sign = leg.side === 'Buy' ? 1 : -1;
        leg.qty = q * sign;
    }

    onLegSideChanging(leg: HedgePositionModel, $event: any) {
        if (leg.side === $event) {
            return;
        }

        this.cloneAndRemoveLeg(leg, 'side');
    }

    private cloneAndRemoveLeg(leg: HedgePositionModel, prop: keyof HedgePositionModel) {

        if (isVoid(leg)) {
            return;
        }

        if (!this.isOwnedLeg(leg)) {
            return;
        }

        if (isVoid(leg[prop])) {
            return;
        }

        const clone = leg.clone();

        clone.resetTransState();

        clone.removed = true;

        const ix = this.positions.indexOf(leg);

        if (ix < 0) {
            return;
        }

        this.positions.splice(ix, 0, clone);

        this.refreshLegs(leg.type);

    }

    @DetectMethodChanges()
    onLegSideChanged(leg: HedgePositionModel) {

        if (!isValidNumber(leg.qty)) {
            return;
        }

        if (leg.side === 'Sell') {
            leg.qty *= -1;
        } else if (leg.side === 'Buy') {
            leg.qty = Math.abs(leg.qty);
        }

    }

    @DetectMethodChanges()
    onHedgeColorChanged(args: DxValueChanged<string>, pGroup: LegsGroup) {
        pGroup.legs.forEach(x => x.color = args.value);
    }

    private async onInstrumentSelected($event: TradingInstrument) {
        this.tradingInstrument = $event;
        const chain = await this._optionsChainService.getChain($event.ticker);
        this.expirationList = chain.expirations;
        this.selectedExpiration = await this.getShortOptionExpiration();
    }

    private saveHgState(stateKey: HgStateKey, value?: any) {
        if (isVoid(stateKey)) {
            return;
        }

        const attr = stateKey.split('.')[1];

        value = value ?? this[attr];

        const userId = this._sessionService.sessionData.userId;

        this._settingsStorageService.setItem(stateKey, value, userId);
    }

    @DetectMethodChanges()
    onStrategyChanged() {
        this.saveHgState('hg.selectedStrategy');
    }

    @DetectMethodChanges()
    onOptionTypeChanged() {
        this.saveHgState('hg.selectedOptionType');
    }

    @DetectMethodChanges()
    onStrikeWidthChanged() {
        this.saveHgState('hg.strikeWidth');
    }

    @DetectMethodChanges()
    onOffsetChanged() {
        this.saveHgState('hg.strikeOffset');
    }

    @DetectMethodChanges()
    onBaseQtyChanged() {
        this.saveHgState('hg.baseQty');
    }

    @DetectMethodChanges()
    onCustomSortChanged() {
        this.autoSort = false;
        this.publishLoading(true);
        setTimeout(() => {
            try {
               this.afterSortOrderChanged();
            } finally {
                this.publishLoading(false);
            }
        }, 250);
    }

    private afterSortOrderChanged() {
        const dtos = this.positions.map(x => x.asDto());
        this._hedgePositionsService.saveHedgePositions(dtos, this._selectedPortfolio);
        this.refreshLegs('Calls & Puts');
        this.onSyncPositionsClicked();
    }

    @DetectMethodChanges()
    onAutoSortChanged() {
        if (this.autoSort) {
            this.publishLoading(true);
            setTimeout(() => {
                try {
                    this.positions.forEach(x => x.groupOrder = null);
                    this.afterSortOrderChanged();
                } finally {
                    this.publishLoading(false);
                }
            }, 250);
        }
        this.saveHgState('hg.autoSort');
    }

    private restoreHgState() {
        const keys = getHgStateKeys();

        const userId = this._sessionService.sessionData.userId;

        keys.forEach(key => {

            const v = this._settingsStorageService.getItem(key, userId) as any;

            switch (key) {
                case "hg.strikeWidth":
                    const width = v; //parseInt(v);
                    this.strikeWidth = width;
                    break;
                case "hg.strikeOffset":
                    const offset = v; // parseInt(v);
                    this.strikeOffset = offset;
                    break;
                case "hg.selectedStrategy":
                    this.selectedStrategy = v as any;
                    break;
                case "hg.selectedOptionType":
                    this.selectedOptionType = v as any;
                    break;
                case "hg.baseQty":
                    const baseQty = v; // parseInt(v);
                    this.baseQty = baseQty;
                    break;
                case "hg.autoSort":
                    const autoSort = v; // === 'true';
                    this.autoSort = autoSort;
                    break;
            }
        });
    }

    onLegExpirationChanging(leg: HedgePositionModel, $event: any) {
        if (leg.expirationDescriptor === $event) {
            return;
        }

        if (isVoid($event)) {
            return;
        }

        this.cloneAndRemoveLeg(leg, 'expiration');
    }


    @DetectMethodChanges()
    onLegExpirationChanged(pGroup: LegsGroup, leg: HedgePositionModel) {

        let affected = [leg];

        if (pGroup.expirationsLinked) {

            const notRemovedLegs = pGroup.legs.filter(x => !x.removed);

            for (const aLeg of notRemovedLegs) {

                if (aLeg.expirationDescriptor === leg.expirationDescriptor) {
                    continue;
                }

                this.cloneAndRemoveLeg(aLeg, 'expiration');

                aLeg.expirationDescriptor = leg.expirationDescriptor;
            }

            affected = pGroup.legs.slice();
        }

        this.updateQuoteSubscriptions(affected);
    }

    canReviewChanges() {

        const after = this.positions.map(x => x.asComparisonUnit());
        const before = this._initialPositions.map(x => getIdentity(x));

        const hasAfterDifference = !arraysEqual(after, this._baseState?.after);
        const hasBeforeDifference = !arraysEqual(before, this._baseState?.before);
        const hasChangesSinceLastReview = hasAfterDifference || hasBeforeDifference;

        const enoughForSync = this.canReviewChangesForSync();

        return enoughForSync && hasChangesSinceLastReview;

    }

    canReviewChangesForSync() {

        if (this.isMergeMode) {
            return false;
        }

        const legsAreValid = this.positions
            .filter(x => !x.removed)
            .every(x => x.isValid());

        if (!legsAreValid) {
            return false;
        }

        const hasDuplicates = this.areThereDuplicateLabelsOrColors();

        if (hasDuplicates) {
            return false;
        }

        return true;
    }

    canSyncPositions() {

        if (!this.isMergeMode) {
            return false;
        }

        const legsAreValid = this.positions
            .filter(x => !x.removed)
            .every(x => x.isValid());

        if (!legsAreValid) {
            return false;
        }

        const hasDuplicates = this.areThereDuplicateLabelsOrColors();

        if (hasDuplicates) {
            return false;
        }

        return true;
    }

    canSyncLabelsAndColors() {

        if (this.isMergeMode) {
            return false;
        }

        const legsAreValid = this.positions
            .filter(x => !x.removed)
            .every(x => x.isValid());

        if (!legsAreValid) {
            return false;
        }

        const hasDuplicates = this.areThereDuplicateLabelsOrColors();

        if (hasDuplicates) {
            return false;
        }

        return true;
    }


    onLegStrikeChanging(leg: HedgePositionModel, $event: number) {
        if (leg.strike === $event) {
            return;
        }

        if (isVoid($event)) {
            return;
        }

        this.cloneAndRemoveLeg(leg, 'strike');
    }

    @DetectMethodChanges()
    onLegStrikeChanged(leg: HedgePositionModel) {

        this.updateQuoteSubscriptions([leg], false, true);

        if (this.autoSort) {
            this.refreshLegs(leg.type);
        }

        this.updateQuoteSubscriptions([leg], true);

        if (leg.strategy === 'BOS') {
            this.recalculateBosStrategy(leg);
        } else if (leg.strategy === 'Sponsored Long') {
            this.recalculateSponsoredLongStrategy(leg);
        }
    }

    canMerge() {
        const totalPackages = this.getAllGroups();
        const hasLegChanges = totalPackages
            .some(x => this.doesGroupHaveLegChanges(x));
        return !hasLegChanges || (hasLegChanges && this.isMergeMode);
    }

    private getAllGroups(): LegsGroup[] {
        const callGroups = this._callLegsByGroup || [];
        const putGroups = this._putLegsByGroup || [];
        const totalGroup = callGroups.concat(putGroups);
        return totalGroup;
    }

    getChangesState(): 'Merge' | 'Review' | 'Colors & Labels' {
        if (this.isMergeMode) {
            return 'Merge';
        }

        const someHaveLegChanges = this.getAllGroups()
            .some(x => this.doesGroupHaveLegChanges(x));

        const someHaveLabelColorChanges = this.getAllGroups()
            .some(x => this.doesGroupHaveLabelOrColorChanges(x));

        if (someHaveLabelColorChanges && !someHaveLegChanges) {
            return 'Colors & Labels';
        }

        return 'Review';
    }

    doesGroupHaveAnyChanges(group: LegsGroup): boolean {
        return this.doesGroupHaveLegChanges(group) ||
            this.doesGroupHaveLabelOrColorChanges(group);
    }

    doesGroupHaveLegChanges(group: LegsGroup): boolean {

        const originalGroup =
            this.initialPositions.filter(x => x.groupId === group.groupId);

        if (isVoid(originalGroup)) {
            return true;
        }

        if (originalGroup.length !== group.legs.length) {
            return true;
        }

        const hasSomeTrans = group.legs.some(x => isValidNumber(x.transQty));

        if (hasSomeTrans && !this.isMergeMode) {
            return true;
        }

        const originalTickers = originalGroup.map(x => `${x.ticker}|${x.qty}`);

        const groupTickers = group.legs.filter(x => !x.removed).map(x => {
            const ticker = makeOptionTicker(
                x.expirationDescriptor,
                x.type,
                x.strike,
                'American'
            );
            return `${ticker}|${x.qty}`
        });

        const tickersSame = originalTickers.sort().toString() === groupTickers.sort().toString();

        return !tickersSame;
    }

    doesGroupHaveLabelOrColorChanges(group: LegsGroup): boolean {
        if (isVoid(this._initialColorsAndLabels)) {
            return false;
        }

        const initialColorsAndLabel = this._initialColorsAndLabels.get(group.groupId);

        if (isVoid(initialColorsAndLabel)) {
            return false;
        }

        return initialColorsAndLabel.label !== group.groupLabel ||
            initialColorsAndLabel.color !== group.groupColor;
    }

    private publishLoading(isLoading: boolean, isAsync?: boolean) {
        if (!isAsync) {
            this._messageBus.publish({
                topic: 'Hg.Loading',
                payload: {isLoading}
            });
        } else {
            this._messageBus.publishAsync({
                topic: 'Hg.Loading',
                payload: {isLoading}
            });
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onAfterStateApplied(payload: { side: "Calls" | "Puts" }) {

        this.publishLoading(true);

        await delay(250);

        const currentPositions = await this._hedgePositionsService
            .getPositionModels(this._selectedPortfolio);

        const initialPositions = currentPositions.map(x => x.asDto());

        this._messageBus.publish({
            topic: 'Hg.InitialPositionsChanged',
            payload: {
                portfolio: this._selectedPortfolio,
                positions: initialPositions
            }
        });

        const type = payload.side === 'Calls' ? 'Call' : 'Put';

        const thisSidePositions = currentPositions.filter(x => x.type === type);

        const otherSidePositions = this.positions.filter(x => x.type !== type);

        this.positions = thisSidePositions.concat(otherSidePositions);

        this.refreshLegs(payload.side);

        if (this.canReviewChangesForSync()) {
            this.onReviewChangesClicked(true);
        }

        await delay(500);

        this.publishLoading(false);


    }

    getGrandTotalsValue(side: 'Call' | 'Put', kind: 'owned' | 'trans') {
        var groups = this.getLegGroups(side);

        if (isVoid(groups)) {
            return undefined;
        }

        const sum = groups
            .map(x => this.getGroupTotalPrice(x, kind))
            .reduce((x, y) => (x || 0) + (y || 0), undefined);

        if (!isValidNumber(sum)) {
            return undefined;
        }

        return sum;
    }

    getGrandTotalsVisibility(side: 'Call' | 'Put' | 'Grand', kind: 'owned' | 'trans'): 'visible' | 'hidden' {

        const groups = side === 'Grand' ? this.getAllGroups() : this.getLegGroups(side);

        const some = groups
            .some(grp => this.getPriceboxVisibility(grp, kind, grp.side) === 'visible');

        return some ? 'visible' : 'hidden';
    }

    getGrandTotalsDisplay(side: 'Call' | 'Put' | 'Grand', kind: 'owned' | 'trans'): 'block' | 'none' {

        if (kind === 'owned') {
            const groups = side === 'Grand' ? this.getAllGroups() : this.getLegGroups(side);

            const some = groups
                .some(grp => this.getOwnPriceboxDisplay() === 'block');

            return some ? 'block' : 'none';
        } else if (kind === 'trans') {
            const groups = side === 'Grand' ? this.getAllGroups() : this.getLegGroups(side);

            const some = groups
                .some(grp => this.getTransPriceboxDisplay('Call') === 'block');

            return some ? 'block' : 'none';
        }
    }

    getGroupTotalPrice(pGroup: LegsGroup, kind?: 'owned' | 'trans'): number {

        if (isVoid(pGroup)) {
            return undefined;
        }

        if (!isValidNumber(pGroup.hcf, true)) {
            const qties = pGroup.legs.map(x => x.absQty);
            const hcf = findHCF(qties);
            pGroup.hcf = hcf;
        }

        const ownershipFilter = (leg: HedgePositionModel) => {

            if (isVoid(kind)) {
                return true;
            }

            if (leg.removed) {
                return kind === 'owned' ? false : true;
            }

            if (leg.selectedForTrans) {
                return true;
            }

            if (this.isOwnedLeg(leg) && kind === 'owned') {
                return true;
            }


            if (!this.isOwnedLeg(leg) && kind === 'trans') {
                return true;
            }

            return false;
        }

        const filteredLegs = pGroup.legs.filter(ownershipFilter);

        const qttiesForHcf = filteredLegs.map(x => {
            if (kind === 'owned') {
                return x.absQty;
            } else {
                if (x.selectedForTrans) {
                    const transQty = x.transQty * Math.sign(x.qty);
                    const netQty = transQty - x.qty;
                    return Math.abs(netQty);
                } else {
                    return x.absQty;
                }
            }
        });

        const hcf = findHCF(qttiesForHcf);

        const ratio = hcf / this.portfolioDefaultQty;

        let totalPx = filteredLegs
            .map(x => {

                const legPx = x.price;

                let legQty: number;

                if (x.selectedForTrans && !x.selectedForMerge && kind === 'trans') {
                    const transQty = x.transQty * Math.sign(x.qty);
                    const netQty = transQty - x.qty;
                    legQty = netQty;
                } else {
                    legQty = x.qty;
                }

                const qty = legQty / hcf;

                let px = legPx * qty;

                if (!this.isOwnedLeg(x) && !x.removed || (x.selectedForTrans && !x.selectedForMerge && kind !== 'owned')) {
                    px *= -1;
                }

                return px;
            })
            .reduce((a, b) => a + b, 0);

        if (isValidNumber(ratio, true)) {
            totalPx = totalPx * ratio;
        }

        if (Math.abs(totalPx) < Number.EPSILON) {
            totalPx = 0;
        }

        return totalPx;
    }

    private updateQuoteSubscriptions(legs: HedgePositionModel[], initial = false, final = false) {

        const oldTickers = initial ? [] : legs.map(x => x.getTicker());

        legs.forEach(x => x.updateTicker());

        const currentTicker = final ? [] : legs.map(x => x.getTicker());

        this._lastQuoteCache.subscribeTickersDiff(oldTickers, currentTicker);

        legs.forEach(leg => {
            const quote = this._lastQuoteCache.getLastQuote(leg.getTicker());

            if (!isVoid(quote)) {
                leg.price = quote.mid;
            }
        });
    }

    getPriceBoxStyleInputAttr(value: number) {
        let style = 'text-align: center;'

        if (!isValidNumber(value, true)) {
            style += 'color: inherit';
            return {style};
        }

        if (value > 0) {
            style += 'color: green';
        } else if (value < 0) {
            style += 'color: red';
        } else {
            style += 'color: inherit';
        }

        return {style};
    }

    getPnLColor(value: number) {

        if (!isValidNumber(value, true)) {
            return undefined;
        }

        if (value > 0) {
            return 'green';
        } else {
            return 'red';
        }

    }

    @DetectMethodChanges()
    removeWholeGroup(pGroup: LegsGroup, side: 'Call' | 'Put') {
        pGroup.legs.forEach(leg => {
            if (this.isOwnedLeg(leg)) {
                leg.removed = true;
            } else {
                const ix = this.positions.indexOf(leg);
                if (ix !== -1) {
                    this.positions.splice(ix, 1);
                }
            }
        });
        this.refreshLegs(side);
    }

    @DetectMethodChanges()
    restoreWholeGroup(pGroup: LegsGroup) {
        pGroup.legs.forEach(leg => leg.removed = false);
    }

    getLegPriceColor(leg: HedgePositionModel, pGroup: LegsGroup) {

        if (!isValidNumber(pGroup.hcf, true)) {
            const qties = pGroup.legs.map(x => x.absQty);
            const hcf = findHCF(qties);
            pGroup.hcf = hcf;
        }

        const qty = leg.qty / pGroup.hcf;
        const px = leg.price;

        const sum = qty * px;

        if (sum === 0) {
            return undefined;
        }

        return sum > 0 ? 'red' : 'green';
    }

    @DetectMethodChanges({isAsync: true})
    async showMergeDialog(side: 'Call' | 'Put', legsGroup: LegsGroup, btnMerge: HTMLDivElement) {

        const sidePackages = this.getLegGroups(side) || [];

        if (sidePackages.length === 0) {
            this._toastr.info('No Packages To Merge With');
            return;
        }

        const packages = sidePackages.map(x => {
            const pckg: HedgePackageToMerge = {
                pckg: x,
                label: x.groupLabel,
                color: x.groupColor,
                groupId: x.groupId,
                selected: x.groupId === legsGroup.groupId
            };
            return pckg;
        });

        const config: HgMergeDialogConfig = {
            target: btnMerge,
            packages,
            mergingPackageLabel: legsGroup.groupLabel,
            comp: this
        }

        let createNewPackage = false;
        try {
            this.isMergeMode = true;
            setTimeout(() => this._changeDetector.detectChanges());
            createNewPackage = await this.mergeDialogCmp.show(config);
        } catch {
            this.cancelMergeOperation();
            return;
        }

        const selectedLegs = sidePackages
            .filter(x => x.selectedForMerge)
            .flatMap(x => x.legs.filter(y => y.selectedForMerge));


        if (selectedLegs.length === 0) {
            this._toastr.error('No Legs Were Selected For Merge');
            this.cancelMergeOperation();
            return;
        }

        this._messageBus.publish({topic: 'Hg.Loading', payload: {isLoading: true}});

        await delay(250);

        const targetGroupId = createNewPackage ? getShortUUID() : legsGroup.groupId;
        const targetLabel = createNewPackage ? undefined : legsGroup.groupLabel;
        const targetColor = createNewPackage ? undefined : legsGroup.groupColor;

        for (const l of selectedLegs) {

            const existingLeg = this.positions
                .find(ep => ep.groupId === targetGroupId
                    && ep.getTicker() === l.getTicker()
                );

            if (!isValidNumber(l.transQty)) {
                if (isVoid(existingLeg)) {
                    l.groupId = targetGroupId;
                    l.label = targetLabel;
                    l.color = targetColor;
                } else {

                    const newQty = existingLeg.qty + l.qty;

                    existingLeg.qty = newQty;

                    if (Math.sign(newQty) === 1) {
                        existingLeg.side = 'Buy';
                    } else if (Math.sign(newQty) === -1) {
                        existingLeg.side = 'Sell';
                    }

                    const ix = this.positions.indexOf(l);
                    if (ix >= 0) {
                        this.positions.splice(ix, 1);
                    }
                }
            } else {

                const asDto = l.asDto();
                const model = await HedgePositionModel.fromDto(asDto, this._optionsChainService);
                model.qty = l.transQty * Math.sign(l.qty);
                model.groupId = targetGroupId;
                model.label = targetLabel;
                model.color = targetColor;

                if (isVoid(existingLeg)) {
                    this.positions.push(model);
                } else {
                    const newQty = existingLeg.qty + model.qty;

                    existingLeg.qty = newQty;

                    if (Math.sign(newQty) === 1) {
                        existingLeg.side = 'Buy';
                    } else if (Math.sign(newQty) === -1) {
                        existingLeg.side = 'Sell';
                    } else {
                        const ix = this.positions.indexOf(existingLeg);
                        if (ix >= 0) {
                            this.positions.splice(ix, 1);
                        }
                    }
                }

                l.qty = l.qty - model.qty;
                l.transQty = undefined;
                l.selectedForTrans = false;
            }

            l.selectedForMerge = false;

            if (l.qty === 0) {
                const lIx = this.positions.indexOf(l);
                if (lIx > -1) {
                    this.positions.splice(lIx, 1);
                }
            }

            if (!isVoid(existingLeg)) {
                const existingLegIx = this.positions.indexOf(existingLeg);
                if (existingLegIx > -1) {
                    this.positions.splice(existingLegIx, 1);
                }
            }
        }

        this.refreshLegs(side);

        setTimeout(() => {
            this._messageBus.publish({topic: 'Hg.Loading', payload: {isLoading: false}});
        }, 500);
    }

    @DetectMethodChanges({isAsync: true})
    async addLeg(pGroup: LegsGroup, side: any) {

        const chain = await this._optionsChainService.getChain(this.tradingInstrument.underlying);

        let expirations = chain.expirations;
        let selectedExpiration = this.selectedExpiration;

        if (!isVoid(pGroup.legs)) {
            expirations = pGroup.legs[0].expirationList;
            selectedExpiration = pGroup.legs
                .map(x => x.expirationDescriptor)
                .sort((a, b) => {
                    return a.daysToExpiration - b.daysToExpiration
                })[0];
        }

        const model = new HedgePositionModel();
        model.underlying = this.tradingInstrument.underlying;
        model.type = side;
        model.expirationList = expirations;
        model.expirationDescriptor = selectedExpiration;
        model.label = pGroup.groupLabel;
        model.groupId = pGroup.groupId;
        model.color = pGroup.groupColor;
        model.groupOrder = pGroup.groupOrder;

        this.positions.push(model);

        this.refreshLegs(side);
    }


    async getShortOptionExpiration(): Promise<OptionExpirationDescriptor> {

        const chain = await this._optionsChainService
            .getChain(this.tradingInstrument.ticker);

        const portfolioPositions
            = await this._apgDataService.getPortfolioPositions(this._selectedPortfolio);

        const pfPositions = portfolioPositions.flatMap(x => x);

        const so = pfPositions.find(x => x.role === 'ShortOption');

        if (isVoid(so)) {
            return isVoid(chain) ? undefined : chain.expirations[0];
        }

        const optionTicker = parseOptionTicker(so.ticker);

        let descriptor = chain.expirations.find(x => x.optionExpirationDate === optionTicker.expiration);

        if (isVoid(descriptor)) {
            descriptor = chain.expirations[0];
        }

        return descriptor;
    }

    @DetectMethodChanges()
    onPackageSelectedForMerge(args: { packageId: string, selected: boolean }) {
        const legsGroup = this.getAllGroups()
            .find(x => x.groupId === args.packageId);

        if (isVoid(legsGroup)) {
            return;
        }

        legsGroup.selectedForMerge = args.selected;
        if (!args.selected) {
            legsGroup.legs.forEach(x => {
                x.selectedForMerge = false;
                this.onSelectedForMergeChanged(x);
            });
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onReviewChangesClicked(byAfterState?: boolean) {

        function processLegs(grp: LegsGroup) {
            grp.legs.forEach(leg => {
                leg.groupId = grp.groupId;
                leg.label = grp.groupLabel;
                leg.color = grp.groupColor;
            });
        }

        const isPortfolioValid = await this.isPortfolioValid();

        if (!isPortfolioValid) {
            return;
        }

        this.getLegGroups('Call')
            .forEach(processLegs);

        this.getLegGroups('Put')
            .forEach(processLegs);

        const groups = Enumerable.from(this.positions)
            .where(x => !x.removed)
            .groupBy(x => x.groupId);

        const labels = groups
            .select(x => x.first().label);

        const distinctLabels = labels.distinct();

        if (labels.count() !== distinctLabels.count()) {
            this._toastr.error('Duplicate group labels are not allowed')
            return;
        }

        const colors = groups.select(x => x.first().color);

        const distinctColors = colors.distinct();

        if (colors.count() !== distinctColors.count()) {
            this._toastr.error('Duplicate group colors are not allowed')
            return;
        }

        const dupLegs = Enumerable.from(this.positions)
            .where(x => !x.removed)
            .select(x => x.asDto())
            .groupBy(x => x.groupId)
            .select(x => {
                const strings = x.select(y => y.ticker).distinct();
                if (strings.count() !== x.count()) {
                    return x.first().label;
                }
                return undefined;
            })
            .where(x => !isVoid(x))
            .toArray();

        if (dupLegs.length > 0) {
            const groups = dupLegs.join(',');
            this._toastr.error(`Please correct duplicate legs for groups: ${groups}`);
            return false;
        }

        this.reviewChangesClicked.emit(byAfterState);
    }

    @DetectMethodChanges()
    afterReviewChanges() {
        const after = this.positions.map(x => x.asComparisonUnit());
        const before = this._initialPositions.map(x => getIdentity(x));

        this._baseState = {before, after};
    }

    @DetectMethodChanges()
    onSyncPositionsClicked() {

        const positions = this.positions.filter(p => !p.removed);

        this._messageBus.publish({
            topic: 'Hg.SyncPositions',
            payload: {
                positions
            }
        });
    }

    isGroupRemoved(pGroup: LegsGroup): boolean {
        return pGroup.legs.every(x => x.removed);
    }

    isOwnedLeg(leg: HedgePositionModel) {
        if (leg.removed) {
            return false;
        }

        const identity = leg.getIdentity();
        if (isVoid(identity)) {
            return false;
        }

        const isInitial = this.initialPositions.some(x => {
            const sameGroup = x.groupId === leg.groupId;
            const sameIdentity = getIdentity(x) === identity;

            return sameGroup && sameIdentity;
        });

        return isInitial;
    }

    getLegPrice(leg: HedgePositionModel, transKind: 'owned' | 'trans'): number {

        let px = leg.price;

        if (!isValidNumber(px)) {
            px = undefined;
        } else {

            if (leg.selectedForTrans) {

                if (transKind === 'trans') {
                    const transQty = leg.transQty * Math.sign(leg.qty);
                    const netQty = transQty - leg.qty;
                    px = leg.price * Math.sign(netQty) * -1;
                } else {
                    px = leg.price * Math.sign(leg.qty);
                }

            } else {
                if (this.isMergeMode || this.isOwnedLeg(leg) || leg.removed) {
                    px = leg.price * Math.sign(leg.qty);
                } else {
                    px = leg.price * Math.sign(leg.qty) * -1;
                }
            }
        }

        return px;
    }

    getPriceboxVisibility(legOrGroup: HedgePositionModel | LegsGroup, kind: 'owned' | 'trans', side?: 'Call' | 'Put') {

        const isLeg = !('legs' in legOrGroup);

        if (isLeg) {

            if (this.isMergeMode) {
                return kind === 'owned';
            }

            legOrGroup = legOrGroup as HedgePositionModel;

            const isOwnedLeg = this.isOwnedLeg(legOrGroup);

            if (kind === 'owned') {
                return isOwnedLeg ? 'visible' : 'hidden';
            }

            if (kind === 'trans') {
                return (isOwnedLeg && !legOrGroup.selectedForTrans) ? 'hidden' : 'visible';
            }
        } else {

            legOrGroup = legOrGroup as LegsGroup;

            if (kind === 'trans') {
                const b = legOrGroup.legs
                    .some(x => {
                        return x.type === side && (!this.isOwnedLeg(x) || x.selectedForTrans);
                    });

                return b ? 'visible' : 'hidden';
            } else {
                const b = legOrGroup.legs.some(x => {
                    return x.type === side && this.isOwnedLeg(x);
                });
                return b ? 'visible' : 'hidden';
            }
        }
    }

    getTransPriceboxDisplay(side: 'Call' | 'Put'): string {
        if (this.isMergeMode) {
            return 'none';
        }

        const b = this.positions
            .some(x => {
                return x.type === side && (!this.isOwnedLeg(x) || x.selectedForTrans);
            });

        return b ? 'block' : 'none';
    }

    getOwnPriceboxDisplay(): string {
        return 'block';
    }

    @DetectMethodChanges()
    private cancelMergeOperation() {

        if (this.isMergeMode) {
            const allGroups = this.getAllGroups();
            const hasChanges = allGroups.some(x => this.doesGroupHaveLegChanges(x));
            if (!hasChanges) {
                this.isMergeMode = false;
            }
        }

        this.positions.forEach(x => x.selectedForMerge = false);

        this.positions.forEach(x => {
            x.selectedForTrans = false;
            x.transQty = undefined;
        });

        this.getLegGroups('Call')?.forEach(x => x.selectedForMerge = false);

        this.getLegGroups('Put')?.forEach(x => x.selectedForMerge = false);
    }

    @DetectMethodChanges()
    onQtyFocusIn(leg: HedgePositionModel) {

        if (!this.isOwnedLeg(leg)) {
            return;
        }

        leg.selectedForTrans = true;
    }

    @DetectMethodChanges()
    onQtyFocusOut(leg: HedgePositionModel) {
        if (this.isMergeMode) {

            if (!isValidNumber(leg.transQty, true)) {
                this._toastr.error('Incorrect Split Qty');
                return;
            }

            if (Math.abs(leg.transQty) > Math.abs(leg.qty)) {
                this._toastr.error('Split Qty Is Too Big');
                return;
            }
        } else {
            if (!isValidNumber(leg.transQty, true)) {
                if (!isNullOrUndefined(leg.transQty)) {
                    this._toastr.error('Transaction Qty Is Not A Valid Number');
                }
                leg.selectedForTrans = false;
                leg.transQty = undefined;
                return;
            }
        }
    }

    @DetectMethodChanges()
    onSelectedForMergeChanged(leg: HedgePositionModel) {
        if (!leg.selectedForMerge) {
            leg.transQty = undefined;
            leg.selectedForTrans = false;
        }
    }

    onQtyBoxInit(transQtyBox: DxNumberBoxComponent) {
        setTimeout(() => {
            transQtyBox.instance.focus();
        });
    }

    @DetectMethodChanges()
    onTranQtyBoxValueChange($event: number, leg: HedgePositionModel) {
        if (!isValidNumber($event, true)) {
            this._toastr.error('Not Valid Transaction/Split Qty');
            return;
        }
        leg.transQty = $event;
    }

    canPasteCombo() {
        return !this.isMergeMode && (this._clipboardService.hasKey('hg.opg-data')) ||
            // this._clipboardService.hasKey('multi-combo')) ||
            this._clipboardService.hasKey('hg.pkg-cmprsn');
        // this._clipboardService.hasKey('combo');
    }

    @DetectMethodChanges({isAsync: true})
    async onPasteComboClicked() {

        const combo = this._clipboardService.get('hg.combo') as ComboHighlightedUIMessage;
        const multiCombo = this._clipboardService.get('hg.multi-combo') as MultiLegOrderDataUIMessage;
        const pkgCmprsnData = this._clipboardService.get('hg.pkg-cmprsn') as ComboHighlightedUIMessage[];
        const opgHgData = this._clipboardService.get('hg.opg-data') as ComboHighlightedUIMessage;

        if (combo) {
            await this.pasteComboFromClipboard(combo);
        } else if (multiCombo) {

            if (multiCombo) {

                await this.pasteComboFromClipboard(multiCombo.main);
                await this.pasteComboFromClipboard(multiCombo.linked);
                await this.pasteComboFromClipboard(multiCombo.secondLinked);
                await this.pasteComboFromClipboard(multiCombo.thirdLinked);

            } else {

                this._toastr.warning('Clipboard is empty');

            }
        } else if (pkgCmprsnData) {
            for (let pkgCmprsnDatum of pkgCmprsnData) {
                await this.pasteComboFromClipboard(pkgCmprsnDatum);
            }
        } else if (opgHgData) {
            await this.pasteComboFromClipboard(opgHgData);
        }

        this.refreshLegs('Calls & Puts');
    }

    private async pasteComboFromClipboard(msg: ComboHighlightedUIMessage) {

        if (!msg.items) {
            return;
        }

        if (!isVoid(msg.hedgingGridTransaction)) {
            await this.pasteHedgingGridTransaction(msg);
            return;
        }

        const comboItems = msg.items
            .filter(x => x.itemType !== PortfolioItemType.Strategy);

        if (comboItems.length === 0) {
            return;
        }

        const distinctTypesCount = Enumerable.from(comboItems).select(x => x.ticker)
            .distinct()
            .select(x => x.indexOf(' Call ') ? 1 : -1)
            .distinct()
            .count();

        if (distinctTypesCount !== 1) {
            this._toastr.error('Cannot Paste Items Of Mixed Option Types');
            return;
        }

        const firstItem = comboItems[0] as ComboHighlightedItem;

        const ul = firstItem.underlying;

        // load an instrument if not selected or changing
        if (!this.tradingInstrument || this.tradingInstrument.ticker !== ul) {
            this._toastr.error('Cannot Paste Strategy Because Underlying Assets Do Not Match');
            return;
        }

        const groupId = getShortUUID();
        const label = msg.strategyName;

        const promises = msg.items.map(async item => {

            const ticker = item.ticker;
            const qty = item.netPosition * item.side;

            const dto: HedgePosition = {
                ticker,
                qty,
                label,
                groupId
            };

            const model = await HedgePositionModel.fromDto(dto, this._optionsChainService);

            return model;
        });

        const models = await Promise.all(promises);

        this.positions.push(...models);
    }

    private async onHedgesCleared(msg: any) {
        const isReload = msg?.isReload;
        if (isReload) {
            return;
        }
        this.initialPositions = [];
        await this.onClearLegsClicked(true);
    }

    private areThereDuplicateLabelsOrColors() {

        const labels = this.areThereDuplicateLabels();

        if (labels) {
            return true;
        }

        const colors = this.areThereDuplicateColors();

        if (colors) {
            return true;
        }

        return false;
    }

    private areThereDuplicateLabels(): boolean {
        const groups = Enumerable.from(this.positions)
            .select(x => x.groupId)
            .distinct()
            .toArray();

        const labels = Enumerable.from(this.positions)
            .select(x => x.label?.trim())
            .distinct();

        const result = labels.count() !== groups.length;

        return result;
    }

    private areThereDuplicateColors(): boolean {
        const groups = Enumerable.from(this.positions)
            .select(x => x.groupId)
            .distinct()
            .toArray();

        const colors = Enumerable.from(this.positions)
            .select(x => x.color)
            .distinct();

        const result = colors.count() !== groups.length;

        return result;
    }

    canAddStrategy() {
        if (isVoid(this.tradingInstrument)) {
            return false;
        }

        if (isVoid(this.selectedStrategy)) {
            return false;
        }

        if (isVoid(this.selectedOptionType)) {
            return false;
        }

        if (!isValidNumber(this.strikeWidth, true)) {
            if (!this.selectedStrategy.startsWith('Option')) {
                return false;
            }
        }

        if (isVoid(this.strikeOffsetMode)) {
            return false;
        }

        if (this.strikeOffsetMode === 'Offset') {
            if (!isValidNumber(this.strikeOffset || 0)) {
                return false;
            }
        } else {
            if (!isValidNumber(this.baseStrike, true)) {
                return false;
            }
        }


        if (!isValidNumber(this.baseQty, true)) {
            return false;
        }

        if (this.isMergeMode) {
            return true;
        }

        return true;
    }

    @DetectMethodChanges()
    changeOffsetMode(offsetMode: 'Offset' | 'Strike') {
        this.strikeOffsetMode = offsetMode;
        if (offsetMode === 'Offset') {
            this.baseStrike = null;
        } else if (offsetMode === 'Strike') {
            this.strikeOffset = null;
        }
    }

    async onSyncColorAndLabelsClicked() {
        this.onSyncPositionsClicked();
    }

    @DetectMethodChanges()
    toggleExpandConfigurator() {
        this.expandConfigurator = !this.expandConfigurator;
    }

    onExpirationsLinkedChanged(pGroup: LegsGroup) {
        this._groupsStateIndex[pGroup.groupId] = pGroup.expirationsLinked;
    }

    @DetectMethodChanges({isAsync: true})
    async resetGroup(pGroup: LegsGroup) {

        this.publishLoading(true);

        this._changeDetector.detectChanges();

        try {
            const initialPositions = this._initialPositions.filter(x => x.groupId === pGroup.groupId);

            const promises = initialPositions
                .map(ip => HedgePositionModel.fromDto(ip, this._optionsChainService));

            const models = await Promise.all(promises);

            let poses = this.positions
                .filter(x => x.groupId !== pGroup.groupId)
                .concat(models);

            this.positions = poses;

            delete this._groupsStateIndex[pGroup.groupId];

            this.refreshLegs(pGroup.side);


        } finally {
            this.publishLoading(false)
        }
    }

    isOwnedGroup(pGroup: LegsGroup) {
        return this._initialPositions.some(x => x.groupId === pGroup.groupId);
    }

    canRevertGroup(pGroup: LegsGroup) {
        return this.doesGroupHaveAnyChanges(pGroup) && this.isOwnedGroup(pGroup);
    }

    getGrandTotal(value: number | undefined, value2: number | undefined) {
        if (isValidNumber(value) || isValidNumber(value2)) {
            return (value || 0) + (value2 || 0);
        }

        return undefined;
    }

    private async pasteHedgingGridTransaction(msg: ComboHighlightedUIMessage) {

        const hedgePositionModels = this.positions
            .filter(x => x.groupId === msg.hedgingGridTransaction.groupId);

        if (hedgePositionModels.length === 0) {
            this._toastr.error('Operation Failed: cannot find hedge positions related to transaction');
            return;
        }

        const groupLabel = hedgePositionModels[0].label;
        const groupColor = hedgePositionModels[0].color;
        const groupId = hedgePositionModels[0].groupId;

        for (let item of msg.items) {

            const existing = hedgePositionModels
                .find(x => x.getTicker() === item.ticker);

            if (isVoid(existing)) {

                const ticker = item.ticker;
                const qty = item.netPosition * item.side;

                const dto: HedgePosition = {
                    ticker,
                    qty,
                    color: groupColor,
                    label: groupLabel,
                    groupId
                };

                const model = await HedgePositionModel
                    .fromDto(dto, this._optionsChainService);

                this.positions.push(model);

                continue;
            }

            const netQty = (item.netPosition * item.side) + existing.qty;

            if (netQty === 0) {
                existing.removed = true;
                continue;
            }

            if (Math.sign(netQty) !== Math.sign(existing.qty)) {
                const clone = existing.clone();
                existing.removed = true;
                clone.qty = netQty;
                clone.side = clone.qty > 0 ? 'Buy' : 'Sell';
                this.positions.push(clone);
                continue;
            }

            existing.transQty = Math.abs(netQty);
            existing.selectedForTrans = true;

        }
    }

    private recalculateBosStrategy(leg: HedgePositionModel) {
        const legs = Enumerable
            .from(this.positions)
            .where(x => x.groupId === leg.groupId)
            .where(x => x.strategy === 'BOS');


        if (legs.count() === 0) {
            return;
        }

        const optionType = leg.type;

        if (!(optionType === 'Call' || optionType === 'Put')) {
            return;
        }

        let debitSpread: Enumerable.IEnumerable<HedgePositionModel>;

        let creditSpread: Enumerable.IEnumerable<HedgePositionModel>;

        if (optionType === 'Call') {
            debitSpread = legs.reverse().take(2);
            creditSpread = legs.reverse().skip(2).take(2);
        } else if (optionType === 'Put') {
            debitSpread = legs.take(2);
            creditSpread = legs.skip(2).take(2);
        }

        const debitSpreadWidth = debitSpread
            .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
            .aggregate(0, (a: number, b: number) => a - b);

        const creditSpreadWidth = creditSpread
            .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
            .aggregate(0, (a: number, b: number) => a - b);

        const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

        if (isValidNumber(creditSpreadQty)) {
            creditSpread.forEach(x => x.qty = creditSpreadQty);
        }
    }

    private recalculateSponsoredLongStrategy(leg: HedgePositionModel) {

        const legs = Enumerable
            .from(this.positions)
            .where(x => x.groupId === leg.groupId)
            .where(x => x.strategy === 'Sponsored Long');


        if (legs.count() === 0) {
            return;
        }

        const optionType = leg.type;

        if (!(optionType === 'Call' || optionType === 'Put')) {
            return;
        }

        let debitSpread: Enumerable.IEnumerable<HedgePositionModel>;

        let creditSpread: Enumerable.IEnumerable<HedgePositionModel>;

        if (optionType === 'Call') {
            debitSpread = legs.reverse().take(2);
            creditSpread = legs.reverse().skip(1).take(2);
        } else if (optionType === 'Put') {
            debitSpread = legs.take(2);
            creditSpread = legs.skip(1).take(2);
        }

        const debitSpreadWidth = debitSpread
            .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
            .aggregate(0, (a: number, b: number) => a - b);

        const creditSpreadWidth = creditSpread
            .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
            .aggregate(0, (a: number, b: number) => a - b);

        const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

        if (isValidNumber(creditSpreadQty)) {
            creditSpread.forEach(x => x.qty = creditSpreadQty);
        }
    }

    getLabelInputAttr(group: LegsGroup) {

        const styleObj = {style: 'text-align: center;'};

        if (isVoid(group)) {
            return styleObj;
        }

        const warningStyle = 'background: yellow; color: black;';

        if (isVoid(group.groupLabel)) {
            styleObj.style = styleObj.style + warningStyle;
            return styleObj;
        }

        const hasDuplicate = this.positions.filter(x => x.groupId !== group.groupId)
            .some(x => x.label === group.groupLabel);

        if (hasDuplicate) {
            styleObj.style = styleObj.style + warningStyle;
        }

        return styleObj;
    }

    showCustomSortDialog() {
        const calls = this.getLegGroups('Call');
        const puts = this.getLegGroups('Put');

        const config: HedgesSortingDialogConfig = {
            calls,
            puts
        };

        this.hedgesSortingDialog.show(config);
    }

    @DetectMethodChanges()
    onHarvestClicked(side: any, pGroup: LegsGroup, btnMerge: HTMLDivElement) {
        pGroup.selectedForHarvest = !pGroup.selectedForHarvest;
        if (!pGroup.selectedForHarvest) {
            pGroup.legs.forEach(x => x.selectedForHarvest = false);
        }
    }

    @DetectMethodChanges({isAsync: true})
    private async showHarvestDialog(pGroup: LegsGroup) {
        const legs = pGroup.legs.filter(x => x.selectedForHarvest);

        const numberOfSides = Enumerable.from(legs).select(x => x.side).distinct().count();

        if (numberOfSides < 2) {
            this._toastr.error('Same Side Legs Are Not Allowed For Harvesting');
            return;
        }

        const numberOfTypes = Enumerable.from(legs).select(x => x.type).distinct().count();

        if (numberOfTypes > 1) {
            this._toastr.error('Different Type Of Options (Calls/Puts) Are Not Allowed For Harvesting');
            return;
        }

        const config : OrdersChainDialogConfig = {
            legs,
            groupName: pGroup.groupLabel,
            groupColor: pGroup.groupColor,
            group: pGroup,
            copyOrdersService: this._copyOrdersToService
        };

        await this.ordersChainDialog.show(config);

        pGroup.selectedForHarvest = false;
        pGroup.legs.forEach(x => x.selectedForHarvest = false);
    }

    @DetectMethodChanges()
    onSelectedForHarvestChanged(leg: HedgePositionModel, pGroup: LegsGroup) {
        const length = pGroup.legs.filter(x => x.selectedForHarvest).length;
        if (length < 2) {
            return;
        }
        setTimeout(() => this.showHarvestDialog(pGroup));
    }

    private async isPortfolioValid() : Promise<boolean> {
        const portfolioPositions
            = await this._apgDataService.getPortfolioPositions(this._selectedPortfolio);

        if (!isVoid(portfolioPositions)) {
            const allPortfolioPositions = portfolioPositions.flatMap(x => x);

            const legsValidnessPromises = allPortfolioPositions.map(async pp => {
                const ticker = parseOptionTicker(pp.ticker);
                if (!isVoid(ticker)) {
                    const chain = await this._optionsChainService.getChain(ticker.underlying);
                    if (!isVoid(chain)) {
                        const isValidExpiration =
                            chain.expirations.some(x => x.optionExpirationDate === ticker.expiration);
                        return isValidExpiration;
                    }
                }
            });

            const legsValidness = await Promise.all(legsValidnessPromises)

            const someNotValid = legsValidness.some(x => !x);

            if (someNotValid) {
                this._toastr.warning('Some Of The Portfolio Positions Are Expired. "Review Changes" Will Not Be Available');
            }

            return !someNotValid;

        } else {
            this._toastr.error('Cannot Determine Portfolio Positions')
        }
    }

    isLegExpired(leg: HedgePositionModel) : boolean {
        let isExpired = false;
        const ticker = leg.getTicker();
        if (isVoid(ticker)) {
            return !isVoid(leg.expiredTicker);
        } else {
            isExpired = isOptionExpired(ticker);
        }
        return isExpired;
    }

    getExpiredLegExpirationText(leg: HedgePositionModel) {
        const optionTicker = parseOptionTicker(leg.expiredTicker);
        if (isVoid(optionTicker)) {
            return "EXPIRED";
        }
        let date = makeGuiFriendlyExpirationDate(optionTicker.expiration);
        date = date + ' (EXPIRED)'
        return date;
    }

    getExpirationPnlAsync(leg: HedgePositionModel): Observable<number> {

        if (isVoid(leg.expiredTicker)) {
            return undefined;
        }

        if (isValidNumber(leg.expirationPnl)) {
            return from([leg.expirationPnl]);
        }

        const optionTicker = parseOptionTicker(leg.expiredTicker);

        const expiration = optionTicker.expiration;

        const underlying = optionTicker.underlying;

        let promise = this._settlementPriceService
            .getSettlementPriceWithRequest(underlying, expiration)
            .then(result => {
                if (!isValidNumber(result)) {
                    return undefined;
                }

                const outcomeQty = leg.qty;

                let delta = (result - leg.strike) * outcomeQty;

                if (leg.type === 'Put') {
                    delta *= -1;
                }

                if (delta < 0) {
                    if (outcomeQty > 0) {
                        delta = 0;
                    }
                } else if (delta > 0) {
                    if (outcomeQty < 0) {
                        delta = 0;
                    }
                }
                let pnl = delta;
                pnl = pnl / this.portfolioDefaultQty;
                leg.expirationPnl = pnl;

                return pnl;
            })
            .finally(() => this._changeDetector.detectChanges());

        const obs = from(promise);

        obs.subscribe((x) => {
            console.log(x);
        })

        return obs;
    }

    isHedgeExpired(pGroup: LegsGroup) {
        return pGroup.legs.some(x => this.isLegExpired(x));
    }

    getHedgeExpirationPnl(pGroup: LegsGroup) {
        const numbers = pGroup.legs.map(x => x.expirationPnl);

        const total = numbers.reduce((p, c) => p + c , 0);

        return total;
    }
}