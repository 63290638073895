import {AfterViewInit, ChangeDetectorRef, Component} from "@angular/core";
import {ICellEditorAngularComp} from "ag-grid-angular";
import {OptionsChainService} from "../../option-chains.service";
import {OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {convertGuiExpirationToIso, DetectMethodChanges, DxValueChanged, makeDayOfWeekDate} from "../../utils";
import {GridApi} from "ag-grid-community";
import {HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";

@Component({
    selector: 'ets-hedge-expiration-selector',
    template: `
      <div>
        <dx-select-box [items]="this.expirationList"
                       [(value)]="this.selectedExpiration"
                       displayExpr="dateWithDaysToExpiration"
                       (onValueChanged)="this.onExpirationChanged($event)"
        ></dx-select-box>
      </div>

    `,
    styles: [``],
})
export class HedgeExpirationCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _optionsChainService: OptionsChainService,
    ) {
    }

    selectedExpiration: OptionExpirationDescriptor;

    expirationList: OptionExpirationDescriptor[] = [];

    api: GridApi;

    comp: HgHedgeMatrixComponent;

    hedgeId: string;

    // don't use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    }

    getValue() {
        return undefined;
    }

    @DetectMethodChanges({isAsync: true})
    async agInit(params: any): Promise<void> {
        this.api = params.api;

        this.comp = params.etsComponent;

        this.hedgeId = params.hedgeId;

        const hedgeData = this.comp.hedgeMatrixDataService.getHedge(this.hedgeId);

        const chain = await this._optionsChainService.getChain('SPX');

        this.expirationList = chain.expirations;

        const currentExpiration = hedgeData.expiration;

        const isoExp = convertGuiExpirationToIso(currentExpiration);

        this.selectedExpiration = chain.expirations
            .find(x => x.optionExpirationDate === isoExp);
    }

    isPopup(): boolean {
        return true;
    }

    getPopupPosition() {
        return 'under';
    }

    onExpirationChanged($event: DxValueChanged<OptionExpirationDescriptor>) {
        if (!$event.event) return;

        const guiExpiration = convertGuiExpirationToIso($event.value.optionExpirationDate);

        const dayOfWeek = makeDayOfWeekDate($event.value.optionExpirationDate);

        const hedgeData = this.comp.hedgeMatrixDataService.getHedge(this.hedgeId);

        hedgeData.expiration = guiExpiration;

        hedgeData.dayOfWeek = dayOfWeek;

        this.comp.hedgeMatrixDataService.onHedgeExpirationChanged(this.hedgeId);

        this.api.stopEditing();

        setTimeout(() => this.api.refreshCells({force: true}));
    }
}